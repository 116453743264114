import { Delete, get, post, put } from '../axios'

// 话题-列表
export const pushList = params => get(`/web/news/message/list`,params);

// 话题-新增
export const pushAdd = params => post(`/web/news/message/pushing`,params);

// 话题-查看
export const pushView = params => get(`/web/news/message/view/${params}`);

// 话题-修改
export const pushUpdate = params => put(`/web/news/message/update`,params);

// 话题-删除
export const pushDelete = params => Delete(`/web/news/message/delete/${params}`);

// 话题-主题分类
export const topicSelectData = () => get(`/web/topic/topicClassify/list/`);
