<template>
  <div class="main-content">
    <pagesHeader title="基本设置" />
    <avue-form
      ref="form"
      v-model="form"
      :option="option"
    >
      <template slot="company">
        <avue-ueditor
          v-model="form.company"
          v-bind="uOptions"
        ></avue-ueditor>
      </template>

      <template slot="menuForm">
        <div class="submit-button">
          <el-button
            type="primary"
            size="medium"
            @click="handleSubmit()"
          >立即生效</el-button>
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import column from "./column";
import { actionUrl } from "@/config/public";
import { manageView, manageUpdate } from "@/api/config/config";
export default {
  name: "service",
  data() {
    return {
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        customConfig: {},
        propsHttp: {
          url: "path",
          res: "data",
        },
      },
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: column.increaseColumn,
      },
    };
  },
  mounted() {
    this.GET_integralView();
  },
  methods: {
    GET_integralView() {
      manageView()
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleSubmit() {
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          manageUpdate(this.form)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.GET_integralView();
              } else {
                this.$message.error(res.msg);
              }
              done();
            })
            .catch((err) => {
              this.$message.error(err.msg);
              done();
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .el-input {
  width: 300px !important;
}
label {
  color: #606266;
  margin: 0 20px;
}
</style>
