import { actionUrl } from '@/config/public';

const manage = {
    column: [
        {
            label: '岗位',
            prop: 'station',
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请选择岗位",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '姓名',
            prop: 'username',
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入姓名",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '性别',
            prop: 'sex',
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请选择性别",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '年龄',
            prop: 'age',
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请选择年龄",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '经验',
            prop: 'exp',
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入经验",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '应聘时间',
            prop: 'recruitTime',
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请选择应聘时间",
                    trigger: "blur",
                },
            ],

        },
        {
            label: '期望薪资',
            prop: 'salaryWant',
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入期望薪资",
                    trigger: "blur",
                },
            ],
        },
        {
            label: '附件',
            prop: 'file',
            type: 'upload',
            // listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            row: true,
            rules: [{
                required: true,
                message: "上传图标",
                trigger: "blur"
            }],
        },

    ],

}
export default manage
