import { Delete, get, post, put } from '../axios'

// 测评回复-列表
export const answerList = params => get(`/web/evaluate/answer/list`, params);

// 测评回复-新增
export const answerAdd = params => post(`/web/evaluate/answer/add`, params);

// 测评回复-查看
export const answerView = params => get(`/web/evaluate/answer/view/${params}`);

// 测评回复-修改
export const answerUpdate = params => put(`/web/evaluate/question/update`, params);

// 测评回复-删除
export const answerDelete = params => Delete(`/web/evaluate/questionPush/delete/${params}`);



