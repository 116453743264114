import { actionUrl } from '@/config/public';
const manage = {
    column: [
        {
            label: '商品分类',
            prop: 'skuClassifyName',
        },
        {
            label: '商品名称',
            prop: 'skuName'
        },
        {
            label: '价格',
            prop: 'price'
        },
        {
            label: '是否有多规格',
            prop: 'isAdd',

        },
        {
            label: '商品描述',
            prop: 'des'
        },
        {
            label: '缩略图',
            prop: 'bImg',
            type: 'img'
        },
        {
            label: '二维码',
            prop: 'codeImg',
            type: 'img'
        },
        {
            label: '详情图',
            prop: 'sImg',
            type: 'img'
        },
        {
            label: '排序',
            prop: 'sort'
        }
    ],
    increaseColumn: [
        {
            label: "客户名称",
            prop: "customerName",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目金额",
            prop: "projectMoney",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目来源",
            prop: "projectFrom",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目金额",
            prop: "projectMoney",
            maxlength: 15,
            showWordLimit: true,
            // rules: [
            //     {
            //         message: "请输入项目金额",
            //         trigger: "blur",
            //     },
            // ],

        },
        {
            label: "项目来源",
            prop: "projectFrom",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    message: "请输入项目来源",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "商务",
            prop: "contactBusiness",
            type: 'select',
            showWordLimit: true,
            dicData: [],
            rules: [
                {
                    required: true,
                    message: "请输入商务",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "联系人",
            prop: "contact",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系人",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "联系人",
            prop: "contact",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系人",
                    trigger: "blur",
                },
            ],

        },

        {
            label: "联系人电话",
            prop: "contactPhone",
            // maxlength: 11,
            slot: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系人电话",
                    trigger: "blur",
                },
                // {
                //     pattern: /^1\d{10}/,
                //     message: "请输入正确手机号",
                // },
            ],
        },
        {
            label: "交付时间",
            prop: "endTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
                type: "datetime",
                format: "yyyy-MM-dd",
            },
        },
        {
            label: "领取时间起止",
            type: 'datetimerange',
            prop: 'receivingTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "测试时间起止",
            type: 'datetimerange',
            prop: 'testTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "质检时间起止",
            type: 'datetimerange',
            prop: 'qualityInspectionTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "项目分类",
            prop: "classify",
            type: "select",
            showWordLimit: true,
            dicData: [],
            props: {
                label: "name",
                value: "id",
            },

        },
        {
            label: "项目名称",
            prop: "projectName",
            maxlength: 15,
            showWordLimit: true,

        },
        {
            label: "项目业绩",
            prop: "projectAchievement",
            maxlength: 15,
            showWordLimit: true,

        },
        // {
        //     label: "项目金额",
        //     prop: "projectMoney",
        //     maxlength: 15,
        //     showWordLimit: true,
        //
        // },
        {
            label: "质检结果",
            prop: "projectQuality",
            type: "select",
            dicData: [
                {
                    label: '卓越',
                    value: '1',
                },
                {
                    label: '优秀',
                    value: '2'
                },
                {
                    label: '合格',
                    value: '3'
                },
                {
                    label: '事故',
                    value: '4'
                },
                {
                    label: '重大事故',
                    value: '5'
                },
                {
                    label: '烂尾',
                    value: '6'
                }
            ],
            props: {
                label: "label",
                value: "value",
            },

        },
        {
            label: "状态",
            prop: "state",
            type: "select",
            rules: [
                {
                    required: true,
                    message: "请选择状态",
                    trigger: "blur",
                },
            ],
            dicData: [
                {
                    label: '未成交',
                    value: 0,
                },
                {
                    label: '待成交',
                    value: 1
                },
                {
                    label: '待立项',
                    value: 2
                },
                {
                    label: '开发中',
                    value: 3
                },
                {
                    label: '待测试',
                    value: 4
                },
                {
                    label: '待质检',
                    value: 5
                },
                {
                    label: '完工',
                    value: 6
                }
            ],
            props: {
                label: "label",
                value: "value",
            },
        },
        {
            label: "客户业务场景描述",
            prop: "des",
            showWordLimit: true,
        },
        {
            label: "产品需求",
            prop: "function",
            showWordLimit: true,
        },

    ],
    increaseColumn1: [
        {
            label: "客户名称",
            prop: "customerName",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目金额",
            prop: "projectMoney",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目来源",
            prop: "projectFrom",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目金额",
            prop: "projectMoney",
            maxlength: 15,
            showWordLimit: true,
            // rules: [
            //     {
            //         message: "请输入项目金额",
            //         trigger: "blur",
            //     },
            // ],

        },
        {
            label: "项目来源",
            prop: "projectFrom",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    message: "请输入项目来源",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "商务",
            prop: "contactBusiness",
            type: 'select',
            showWordLimit: true,
            dicData: [],
            rules: [
                {
                    required: true,
                    message: "请输入商务",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "联系人",
            prop: "contact",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系人",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "联系人电话",
            prop: "contactPhone",
            // maxlength: 11,
            slot: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系人电话",
                    trigger: "blur",
                },
                // {
                //     pattern: /^1\d{10}/,
                //     message: "请输入正确手机号",
                // },
            ],
        },
        {
            label: "交付时间",
            prop: "endTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
                type: "datetime",
                format: "yyyy-MM-dd",
            },
        },
        {
            label: "领取时间起止",
            type: 'datetimerange',
            prop: 'receivingTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "测试时间起止",
            type: 'datetimerange',
            prop: 'testTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "质检时间起止",
            type: 'datetimerange',
            prop: 'qualityInspectionTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "项目分类",
            prop: "classify",
            type: "select",
            showWordLimit: true,
            dicData: [],
            props: {
                label: "name",
                value: "id",
            },
            rules: [
                {
                    required: true,
                    message: "请选择项目分类",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "项目名称",
            prop: "projectName",
            maxlength: 15,
            showWordLimit: true,

        },
        {
            label: "项目业绩",
            prop: "projectAchievement",
            maxlength: 15,
            showWordLimit: true,

        },
        // {
        //     label: "项目金额",
        //     prop: "projectMoney",
        //     maxlength: 15,
        //     showWordLimit: true,
        //
        // },
        {
            label: "质检结果",
            prop: "projectQuality",
            type: "select",
            dicData: [
                {
                    label: '卓越',
                    value: '1',
                },
                {
                    label: '优秀',
                    value: '2'
                },
                {
                    label: '合格',
                    value: '3'
                },
                {
                    label: '事故',
                    value: '4'
                },
                {
                    label: '重大事故',
                    value: '5'
                },
                {
                    label: '烂尾',
                    value: '6'
                }
            ],
            props: {
                label: "label",
                value: "value",
            },

        },
        {
            label: "状态",
            prop: "state",
            type: "select",
            rules: [
                {
                    required: true,
                    message: "请选择状态",
                    trigger: "blur",
                },
            ],
            dicData: [
                {
                    label: '未成交',
                    value: 0,
                },
                {
                    label: '待成交',
                    value: 1
                },
                {
                    label: '待立项',
                    value: 2
                },
                {
                    label: '开发中',
                    value: 3
                },
                {
                    label: '待测试',
                    value: 4
                },
                {
                    label: '待质检',
                    value: 5
                },
                {
                    label: '完工',
                    value: 6
                }
            ],
            props: {
                label: "label",
                value: "value",
            },
        },
        {
            label: "客户业务场景描述",
            prop: "des",
            showWordLimit: true,
        },
        {
            label: "产品需求",
            prop: "function",
            showWordLimit: true,
        },
        {
            label: '立项单图片',
            prop: 'startImg',
            type: 'upload',
            span: 24,
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            row: true,
            rules: [{
                required: true,
                message: "请上传立项单图片",
                trigger: "blur"
            }],
        },
        {
            label: "立项单起止时间",
            type: 'datetimerange',
            prop: 'establishmentTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
                required: true,
                message: "请选择立项单起止时间",
                trigger: "blur"
            }],
        },
    ],
    increaseColumn2: [
        // {
        //     label: "客户名称",
        //     prop: "customerName",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     rules: [
        //         {
        //             required: true,
        //             message: "请输入客户名称",
        //             trigger: "blur",
        //         },
        //     ],
        // },
        {
            label: "项目名称",
            prop: "projectName",
            maxlength: 15,
            showWordLimit: true,
            rules: [{
                required: true,
                message: "请输入项目名称",
                trigger: "blur",
            }]
        },
        {
            label: "关联商务",
            prop: "contactBusiness",
            type: 'select',
            showWordLimit: true,
            dicData: [],
            rules: [
                {
                    required: true,
                    message: "请输入关联商务",
                    trigger: "blur",
                },
            ],
        },
        // {
        //     label: "联系人",
        //     prop: "contact",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     rules: [
        //         {
        //             required: true,
        //             message: "请输入联系人",
        //             trigger: "blur",
        //         },
        //     ],

        // },
        {
            label: "联系电话",
            prop: "contactPhone",
            // maxlength: 11,
            slot: true,
            rules: [
                {
                    required: true,
                    message: "请输入联系电话",
                    trigger: "blur",
                },
                // {
                //     pattern: /^1\d{10}/,
                //     message: "请输入正确手机号",
                // },
            ],
        },
        {
            label: "项目来源",
            prop: "projectFrom",
            maxlength: 15,
            showWordLimit: true,
            span: 12,
            row: true,
            rules: [
                {
                    required: true,
                    message: "请输入客户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "项目类型",
            prop: "classify",
            type: "select",
            showWordLimit: true,
            dicData: [],
            props: {
                label: "name",
                value: "id",
            },
            rules: [
                {
                    required: true,
                    message: "请选择项目类型",
                    trigger: "blur",
                },
            ],

        },
        {
            label: "项目状态",
            prop: "state",
            type: "select",
            rules: [
                {
                    required: true,
                    message: "请选择项目状态",
                    trigger: "blur",
                },
            ],
            dicData: [
                {
                    label: '未成交',
                    value: 0,
                },
                {
                    label: '待成交',
                    value: 1
                },
                {
                    label: '待立项',
                    value: 2
                },
                {
                    label: '开发中',
                    value: 3
                },
                {
                    label: '待测试',
                    value: 4
                },
                {
                    label: '待质检',
                    value: 5
                },
                {
                    label: '完工',
                    value: 6
                }
            ],
            props: {
                label: "label",
                value: "value",
            },

        },
        // {
        //     label: "合同金额",
        //     prop: "moneyGet",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请输入合同金额",
        //     //     trigger: "blur"
        //     // }],
        //
        // },
        {
            label: "项目业绩",
            prop: "projectAchievement",
            maxlength: 15,
            showWordLimit: true,

        },
        {
            label: "需求描述",
            prop: "des",
            showWordLimit: true,
        },
        {
            label: "文档链接",
            prop: "function",
            showWordLimit: true,
        },
        {
            label: "结算时间",
            type: 'datetime',
            prop: 'overTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            // rules: [{
            //     required: true,
            //     message: "请选择结算时间",
            //     trigger: "blur"
            // }],
        },
        // {
        //     label: "开发成本",
        //     prop: "moneySpend",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请输入开发成本",
        //     //     trigger: "blur"
        //     // }],
        //
        // },
        // {
        //     label: "渠道成本",
        //     prop: "moneyTake",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请输入渠道成本",
        //     //     trigger: "blur"
        //     // }],
        // },
        {
            label: "质检结果",
            prop: "projectQuality",
            type: "select",
            dicData: [
                {
                    label: '卓越',
                    value: '1',
                },
                {
                    label: '优秀',
                    value: '2'
                },
                {
                    label: '合格',
                    value: '3'
                },
                {
                    label: '事故',
                    value: '4'
                },
                {
                    label: '重大事故',
                    value: '5'
                },
                {
                    label: '烂尾',
                    value: '6'
                }
            ],
            props: {
                label: "label",
                value: "value",
            },

        },
        {
            label: "立项时间",
            span: 12,
            row: true,
            type: 'datetimerange',
            prop: 'establishmentTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            // rules: [{
            //     required: true,
            //     message: "请选择立项单起止时间",
            //     trigger: "blur"
            // }],
        },
        {
            label: "报名时间",
            span: 12,
            row: true,
            type: 'datetimerange',
            prop: 'receivingTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "开发时间",
            span: 12,
            row: true,
            type: 'datetimerange',
            prop: 'workTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "测试时间",
            span: 12,
            row: true,
            type: 'datetimerange',
            prop: 'testTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "质检时间起止",
            span: 12,
            row: true,
            type: 'datetimerange',
            prop: 'qualityInspectionTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
            label: "交付时间",
            span: 12,
            row: true,
            prop: "endTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
                type: "datetime",
                format: "yyyy-MM-dd",
            },
        },
        // {
        //     label: '立项单',
        //     prop: 'startImg',
        //     type: 'upload',
        //     span: 8,
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请上传立项单图片",
        //     //     trigger: "blur"
        //     // }],
        // },
        // {
        //     label: '测试单',
        //     prop: 'testImg',
        //     type: 'upload',
        //     span: 8,
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请上传测试单图片",
        //     //     trigger: "blur"
        //     // }],
        // },
        // {
        //     label: '完工单',
        //     prop: 'overImg',
        //     type: 'upload',
        //     span: 8,
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     // rules: [{
        //     //     required: true,
        //     //     message: "请上传完工单图片",
        //     //     trigger: "blur"
        //     // }],
        // },




    ],
}
export default manage
