import { get } from '../axios'

// 文章分类-列表
export const manageList = params => get(`/web/calendar/mouth/getProjectList`, params);

// 文章分类-选择器列表
export const classifySelectList = () => get(`/web/news/manage/add/classifyList`);

// 文章分类-查看
export const classifyView = params => get(`/web/news/manage/view/${params}`);

// 文章分类-查看
export const getViewPerformanceHandle = params => get(`/web/calendar/mouth/getProjectDetailsList`, params);





