import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '员工',
            prop: 'fullName'
        },
        {
            label: '评价',
            prop: 'stat'
        },
        // {
        //     label: '海报',
        //     prop: 'img',
        //     dataType: 'string',
        //     type: 'img',
        // },
        // // {
        //     label: '上榜理由',
        //     prop: 'reason'
        // },
        {
            label: '展示时间',
            prop: 'startTime',
            formatter: (row, column, cellValue) => {
                return cellValue + ' ~ ' + row.endTime
            }
        },
        {
            label: '状态',
            prop: 'state',
            // type: 'radio',
            // dicData: [
            //     {
            //         label: 1,
            //         value: "展示",
            //     },
            //     {
            //         label: 2,
            //         value: "不展示",
            //     },
            // ]
        }
    ],
    columnIncrease: [
        {
            label: '员工',
            prop: 'userId',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请选择员工',
            rules: [{
                required: true,
                message: "请选择员工",
                trigger: "blur"
            }],
        },
        {
            label: '评价',
            prop: 'stat',
            span: 24,
            row: true,
            // maxlength: 4,
            placeholder: '请输入评价',
            rules: [{
                required: true,
                message: "请输入评价",
                trigger: "blur"
            }],
        },
        // {
        //     label: '海报',
        //     prop: 'img',
        //     type: 'upload',
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     span: 24,
        //     row: true,
        //     rules: [{
        //         required: true,
        //         message: "请上传海报",
        //         trigger: "blur"
        //     }],
        // },
        {
            label: '上榜理由',
            prop: 'reason',
            span: 24,
            row: true,
            placeholder: '请输入上榜理由',
            rules: [{
                required: true,
                message: "请输入上榜理由",
                trigger: "blur"
            }],
        },
        {
            label: "展示时间",
            prop: "showTime",
            type: "daterange",
            valueFormat: "yyyy-MM-dd",
            span: 24,
            placeholder: '请选择展示时间',
            rules: [{
                required: true,
                message: "请选择展示时间",
                trigger: "blur"
            }],
        },
        {
            label: '状态',
            prop: 'state',
            span: 24,
            row: true,
            maxlength: 9,
            placeholder: '请选择状态',
            rules: [{
                required: true,
                message: "请选择状态",
                trigger: "blur"
            }],
        },
    ]
}
export default column
