import { Delete, get, post, put } from '../axios'

// 荣誉管理-列表
export const manageList = params => get(`/web/honor/label/list`,params);

// 文章分类-新增
export const manageAdd = params => post(`/web/honor/label/add`,params);

// 文章分类-查看
export const manageView = params => get(`/web/honor/label/view/${params}`);

// 文章分类-修改
export const manageUpdate = params => put(`/web/honor/label/update`,params);

// 文章分类-删除
export const manageDelete = params => Delete(`/web/honor/label/delete/${params}`);


// 颁发荣誉-发布
export const sendList = params => get(`/web/honor/label/userLabel`,params);

// 颁发荣誉-查看
export const sendView = params => get(`/web/honor/label/userView/${params}`);

// 颁发荣誉-新增
export const sendAdd = params => post(`/web/honor/send/list`,params);

// 颁发荣誉-删除
export const sendDetele = params => Delete(`/web/honor/userLabel/delete/${params}`);
