<template>
  <div class="main-content">
    <pagesHeader title="员工信息审核" />
    <avue-crud
        :data="tableData"
        :option="indexOption"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="search">
          <el-input size="medium" v-model="searchData.name" clearable :maxlength="100" placeholder="输入用户名称" class="marginRight10 width300 marginBottom10" />
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
            type="text"
            size="mini"
            icon="el-icon-view"
            @click="handleView(row)"
        >
          查看
        </el-button>
        <el-button
            type="text"
            size="mini"
            icon="el-icon-edit"
            @click="handleExamine(row)"
            v-if="row.state === 1"
        >
          审核
        </el-button>
      </template>
    </avue-crud>


    <el-dialog
        class="region"
        title="信息"
        :visible.sync="show"
        width="50%"
        top="5vh"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="infoData"
                 :option="formOption">
        <template slot="img">
          <div class="userImg">
            <el-image
                style="width: 178px; height: 178px"
                :src="infoData.img"
                :preview-src-list="[infoData.img]">
            </el-image>
          </div>
        </template>
        <template slot="fullName">{{ infoData.fullName }}</template>
        <template slot="sex">{{ infoData.sex }}</template>
        <template slot="phone">{{ infoData.phone }}</template>
        <template slot="sosPhone">{{ infoData.sosPhone }}</template>
        <template slot="wechatNum">{{ infoData.wechatNum }}</template>
        <template slot="qqNum">{{ infoData.qqNum }}</template>
        <template slot="email">{{ infoData.email }}</template>
        <template slot="education">{{ education[infoData.education] }}</template>
        <template slot="school">{{ infoData.school }}</template>
        <template slot="schoolMajor">{{ infoData.schoolMajor }}</template>
        <template slot="address">{{ infoData.address }}</template>
      </avue-form>
    </el-dialog>

    <el-dialog
        title="审核"
        :visible.sync="examineShow"
        width="30%"
        :before-close="handleExamineClose">
      <avue-form ref="form"
                 v-model="examineForm"
                 :option="formExamineOption"
                 @submit="examineSubmit">
        <template slot="menuForm">
          <el-button @click="handleClose" icon="el-icon-close">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

  </div>
</template>

<script>
import { list, view, update } from "@/api/system/examine";
import { indexOption } from "./data"
export default {
  name: "index",
  data(){
    return{
      indexOption,
      searchData: {
        name: ''
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [],
      showLoading: false,

      show: false,
      infoData: {},
      education: {
        1: '高中',
        2: '专科',
        3: '本科',
        4: '硕士'
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: [
          {
            label: '个人照片',
            prop: 'img',
            type: 'upload',
            listType: 'picture-img',
            span: 24,
          },
          {
            label: '真实姓名',
            prop: 'fullName',
            span: 12
          },
          {
            label: '性别',
            prop: 'sex',
            span: 12
          },
          {
            label: '手机号',
            prop: 'phone',
            span: 12
          },
          {
            label: '紧急联系人手机号',
            prop: 'sosPhone',
            span: 12
          },
          {
            label: '微信号',
            prop: 'wechatNum',
            span: 12
          },
          {
            label: 'qq号',
            prop: 'qqNum',
            span: 12
          },
          {
            label: '邮箱',
            prop: 'email',
            span: 12
          },
          {
            label: '学历',
            prop: 'education',
            span: 12
          },
          {
            label: '毕业院校',
            prop: 'school',
            span: 12
          },
          {
            label: '专业',
            prop: 'schoolMajor',
            span: 12
          },
          {
            label: '地址',
            prop: 'address',
            span: 12
          }
        ]
      },

      examineShow: false,
      examineForm: {
        id: '',
        state: 2
      },
      formExamineOption: {
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '状态',
            prop: 'state',
            type: 'radio',
            span: 24,
            dicData: [
              {
                label: '审核通过',
                value: 2
              },
              {
                label: '审核驳回',
                value: 3
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleView(row){
      view(row.id).then(res => {
        if (res.code === 200) {
          this.infoData = res.data
          this.show = true
        }else{
          this.$message.error(res.msg)
        }
      });
    },
    handleExamine(row){
      console.log(row)
      this.examineForm.id = row.id
      this.examineShow = true
    },
    onLoad() {
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    handleSearch() {
      this.page = this.$options.data().page
      this.onLoad();
    },
    handleReset() {
      this.searchData = this.$options.data().searchData
      this.page = this.$options.data().page
      this.onLoad();
    },

    handleClose(){
       this.show = false;
    },

    examineSubmit(form,done){
      update(form).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.examineShow = false
          this.examineForm = this.$options.data().examineForm
          this.onLoad()
        }else{
          this.$message.error(res.msg)
          done()
        }
      }).catch(()=>{
        done()
      });
    },
    handleExamineClose(){
      this.examineShow = false;
      this.examineForm = this.$options.data().examineForm
    }
  }
}
</script>

<style scoped>
.search{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.marginRight10{
  margin-right: 10px;
}
.width300{
  width: 300px;
}
.marginBottom10{
  margin-bottom: 10px;
}
.userImg{
  width: 178px;
  height: 178px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px dashed #e2e2e2;
}
</style>