<template>
  <div class="main-content">
    <pagesHeader title="测评列表" />
    <avue-crud
        :data="tableData"
        :option="indexOption"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex marginBottom10">
          <div class="month marginBottom10 marginRight10">
            <el-date-picker
                v-model="searchData.date"
                type="month"
                style="margin-right: 10px"
                placeholder="选择月">
            </el-date-picker>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>

      <template slot="headPortrait" slot-scope="{ row }">
        <div style="display: flex;align-items: center;justify-content: center">
          <el-image
              style="width: 44px; height: 44px; display: block"
              :src="row.img"
              :preview-src-list="[row.img]">
          </el-image>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
      </template>
    </avue-crud>


    <el-dialog
        title="编辑 "
        :visible.sync="show"
        width="40%"
        :before-close="handleClose">
      <avue-form
          ref="form"
          v-model="form"
          :option="formOption"
          @submit="handleSave"
      >
        <template slot="fullName">
          <div>{{ form.fullName }}</div>
        </template>
        <template slot="headPortrait">
          <el-image
              style="width: 136px; height: 136px;border: 1px dashed #d9d9d9;border-radius: 6px;"
              :src="form.headPortrait">
          </el-image>
        </template>
        <template slot="point">
          <el-input v-model="form.point"
                    placeholder="测评分数"
                    clearable
                    :maxlength="6"
                    @blur="changeBlur($event, 'point', 100)"
                    onkeyup="this.value=this.value.replace(/[^\d]+/g,'')" />
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { indexOption } from './option'
import { list, update } from '@/api/check/manage'
import { actionUrl } from "@/config/public"
export default {
  name: "index",
  data(){
    return{
      indexOption,
      searchData: {
        date: new Date()
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,

      show: false,
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '人员名称',
            prop: 'fullName',
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入人员名称', trigger: 'blur' }
            ],
          },
          {
            label: '头像',
            prop: 'headPortrait',
            rules: [
              { required: true, message: '请上传头像', trigger: 'blur' }
            ],
          },
          {
            label: '评测图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: actionUrl,
            // tip: '建议尺寸：750*360,最大20M',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{ required: true, message: '请上传评测图片', trigger: 'blur' }],
            change: (e)=>{
              if(e.value !== ''){
                this.$refs.form.clearValidate(`image`);
              }
            }
          },
          {
            label: '评测分数',
            prop: 'point',
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入评测分数', trigger: 'blur' }
            ],
          }
        ]
      }
    }
  },
  methods: {
    handleSearch(){
      this.onLoad()
    },
    handleReset(){
      this.searchData.date = new Date();
      this.onLoad()
    },
    onLoad(){
      this.showLoading = true;
      const year = this.searchData.date.getFullYear();
      const month = this.searchData.date.getMonth() + 1 < 10 ? `0${this.searchData.date.getMonth() + 1}` : this.searchData.date.getMonth() + 1;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        checkTime: `${year}-${month}`
      }).then(res => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    handleEdit(row){
      this.show = true;
      this.form = row;
    },
    handleSave(form,done){
      update({
        id: this.form.id,
        point: this.form.point,
        image: this.form.image
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('编辑成功');
          this.show = false;
          this.onLoad();
          done()
        }else{
          this.$message.error(res.msg);
          done()
        }
      }).catch(() => {
        done()
      })
    },
    handleClose(){
      this.show = false;
    },
    changeBlur(e, key, max){
      const value = e.target.value;
      if (value) {
        if (isNaN(value)) {
          e.target.value = ''
          this.form[key] = ''
        } else {
          if (max) {
            if (Number(value) > Number(max)) {
              this.form[key] = Number(max)
            } else {
              this.form[key] = Number(value)
            }
          } else {
            this.form[key] = value
          }
        }
      } else {
        this.form[key] = '';
        e.target.value = ''
      }
    }
  }
}
</script>

<style scoped>
.flex{
  display: flex;
  align-items: center;
}
.month{
  width: 300px;
}
.marginBottom10{
  margin-bottom: 10px;
}
.marginRight10{
  margin-right: 10px;
}
/deep/ .avue-upload__avatar{
  width: 136px;
  height: 136px;
}
/deep/ .avue-upload__icon{
  width: 136px;
  height: 136px;
  line-height: 136px;
}
</style>