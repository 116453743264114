<template>
  <div class="main-content">
    <pagesHeader :title="this.$route.params.id != 0?'编辑':'新增'" />
    <avue-form
      ref="form"
      v-model="form"
      :option="option"
    >

      <!-- <template slot="reCheckUserIds">
        <el-select
          v-model="form.reCheckUserIds"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in reCheckUserIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="checkUserIds">
        <el-select
          v-model="form.checkUserIds"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in checkUserIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="userIds">
        <el-select
          v-model="form.userIds"
          multiple
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in userIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template> -->

    </avue-form>

    <div>
      <el-form
        label-width="80px"
        v-for="(item,index) in projectWorkActualList"
        :key="index"
      >
        <el-form-item>
          <i
            class="el-icon-circle-plus-outline"
            @click="addProjectWorkActualList"
          ></i>

          <i
            v-if="index>0"
            class="el-icon-remove-outline"
            @click="delProjectWorkActualList(index)"
          ></i>
        </el-form-item>

        <div style="width: 50%">
          <el-form-item label="问题类型">

            <el-select
              v-model="projectWorkActualList[index].questionType"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in qusetionTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="问题名称">
            <el-input v-model="projectWorkActualList[index].questionName"></el-input>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="最大分值">
            <!--            <el-input-numbe-->
            <!--                :max="100"-->
            <!--              oninput="value=value.replace(/[^\d]/g,'');"-->
            <!--              v-model="projectWorkActualList[index].questionAnswerMax"-->
            <!--            ></el-input-numbe>-->
            <el-input-number
              v-model="projectWorkActualList[index].questionAnswerMax"
              :min="1"
              :max="99"
              label="描述文字"
            ></el-input-number>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="submit-button">
      <el-button
        size="medium"
        @click="$router.go(-1)"
      >取消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { pickBy } from "lodash";
import {
  questionView,
  questionUpdate,
  questionAdd,
} from "@/api/question/manage";
import { staffViewHandle, questionTypeHandle } from "@/api/selectApi/selectApi";
import column from "./column";
export default {
  name: "edit",
  data() {
    return {
      qusetionTypeList: [],
      stateList: [
        {
          label: "未成交",
          value: 7,
        },
        {
          label: "待成交",
          value: 1,
        },
        {
          label: "待立项",
          value: 2,
        },
        {
          label: "开发中",
          value: 3,
        },
        {
          label: "待测试",
          value: 4,
        },
        {
          label: "待质检",
          value: 5,
        },
        {
          label: "完工",
          value: 6,
        },
      ],
      reCheckUserIdsList: [],
      checkUserIdsList: [],
      userIdsList: [],
      projectWorkActualList: [{}],
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.increaseColumn,
      },
    };
  },
  mounted() {
    this.questionTypeHandle();
    // this.reCheckUserIdsListHandle();
    // this.checkUserIdsListHandle();
    // this.userIdsListHandle();
    if (this.$route.params.id != 0) {
      this.userManageView();
    }
  },
  methods: {
    async questionTypeHandle() {
      try {
        const { data } = await questionTypeHandle();
        this.qusetionTypeList = data;
      } catch (error) {
        console.error(error);
      }
    },
    async userManageView() {
      try {
        const { data } = await questionView(this.$route.params.id);
        this.form = {
          // reCheckUserIds: data?.reCheckUserIds,
          // checkUserIds: data?.checkUserIds,
          // userIds: data?.userIds.split(","),
          name: data?.name,
        };
        if (data?.questionDetails.length > 0) {
          this.projectWorkActualList = data?.questionDetails.map((item) => {
            return {
              questionAnswerMax: item.questionAnswerMax,
              questionName: item.questionName,
              questionType: item.questionType,
            };
          });
        } else {
          this.projectWorkActualList = [
            {
              userId: "",
              achievement: "",
              time: [],
              timeSpend: "",
            },
          ];
        }
      } catch (error) {
        console.error(error);
      }
    },
    // async reCheckUserIdsListHandle() {
    //   try {
    //     const { data } = await staffViewHandle(1);
    //     this.reCheckUserIdsList = data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async checkUserIdsListHandle() {
    //   try {
    //     const { data } = await staffViewHandle(2);
    //     this.checkUserIdsList = data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async userIdsListHandle() {
    //   try {
    //     const { data } = await staffViewHandle(3);
    //     this.userIdsList = data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    delProjectWorkActualList(index) {
      this.projectWorkActualList.splice(index, 1);
    },
    addProjectWorkActualList() {
      const isStata = this.projectWorkActualList.find(
        (item) =>
          !item.questionType || !item.questionName || !item.questionAnswerMax
      );
      if (isStata) {
        this.$message({
          message: "请先完善信息在添加输入框！",
          type: "warning",
        });

        return;
      }
      this.projectWorkActualList.push({});
    },
    handleSubmit() {
      const isStata = this.projectWorkActualList.find(
        (item) =>
          !item.questionType || !item.questionName || !item.questionAnswerMax
      );
      if (isStata) {
        this.$message({
          message: "请先完善信息在保存！",
          type: "warning",
        });

        return;
      }
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          const from = pickBy(this.form, (value) => value !== "");
          const newData = {
            name: from.name,
            // checkUserIds: from.checkUserIds,
            // reCheckUserIds: from.reCheckUserIds,
            // userIds: from.userIds.toString(),
            questionDetails: this.projectWorkActualList,
          };

          if (this.$route.params.id != 0) {
            newData.id = this.$route.params.id;
            questionUpdate(newData)
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.$router.push({
                    name: "evaluateQuestionList",
                  });
                }
              })
              .catch((err) => {
                this.$message.error(err);
              });
          } else {
            questionAdd(newData)
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    message: "录入成功",
                    type: "success",
                  });
                  this.$router.push({
                    name: "evaluateQuestionList",
                  });
                }
              })
              .catch((err) => {
                this.$message.error(err);
              });
          }
        }
      });
    },
    GetRotationBannerAdd(done) {},
  },
};
</script>

<style scoped>
.submit-button {
  padding: 50px 0;
}

.submit-button .el-button {
  padding: 10px 50px;
}

.avue-form >>> .el-input__suffix {
  display: none;
}

.el-icon-circle-plus-outline {
  position: relative;
  top: 60px;
  left: 50%;
  font-size: 40px;
}
.el-icon-remove-outline {
  position: relative;
  top: 60px;
  left: 51%;
  font-size: 40px;
}
.el-input__inner,
.el-input__inner {
  width: 100% !important;
}
</style>
