<template>
  <div class="main-content">
    <pagesHeader title="上传测评" />
    <div class="search" style="margin-bottom: 20px">
      <el-date-picker
          v-model="search.date"
          type="month"
          style="margin-right: 10px"
          :picker-options="pickerOptions"
          placeholder="选择月">
      </el-date-picker>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="info" @click="handleReset">重置</el-button>
    </div>
    <el-row>
      <el-col :span="2" v-for="(o, index) in infoData" :key="index" :offset="1" :pull="1">
        <el-card :body-style="{ padding: '0px' }" style="margin-bottom: 20px">
          <div>
            <el-image :src="o.img" class="image"
                      style="width: 100%;height: 178px;"
            ></el-image>
            <div style="display: flex;align-items: center;justify-content: center">
              <el-button size="medium" type="text" disabled style="color: #339ef2">{{ o.fullName }}</el-button>
            </div>
          </div>
          <div style="padding: 14px;">
            <div v-if="o.state === 2" style="display: flex;align-items: center;justify-content: center">
              <Upload @change="handleChange" :image-url="o.image" :index="index"></Upload>
            </div>
            <div v-else style="display: flex;align-items: center;justify-content: center">
              <el-image
                  class="image1"
                  v-if="o.state === 1" :src="o.image"
                  :preview-src-list="[o.image]">
              </el-image>
            </div>
            <el-input v-model="o.point" style="margin-top: 10px"
                      placeholder="测评分数"
                      clearable
                      :disabled="o.state === 1"
                      :maxlength="6"
                      @blur="changeBlur($event, index, 100)"
                      onkeyup="this.value=this.value.replace(/[^\d]+/g,'')" />
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="flex">
      <el-button type="primary" @click="handelSubmit" :disabled="disabled">提交</el-button>
    </div>
  </div>
</template>

<script>
import Upload from "./upload.vue";
import { view, update } from "@/api/check/upload";
export default {
  name: "index",
  components: {
    Upload
  },
  data(){
    return{
      search: {
        date: new Date()
      },
      infoData: [],
      disabled: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  mounted() {
    this.getView()
  },
  methods: {
    handelSubmit(){
      const list = this.infoData.map(item => {
        return item.state === 2 ? item : null
      }).filter(item => item)
      if(list.some(o => o.point === '')){
        this.$message.error('请填写测评分数')
        return
      }else if (list.some(o => !o.image)){
        this.$message.error('请上传测评图片')
        return
      }
      this.$confirm('确认提交吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          users: list.map(item => {
            return {
              id: item.id,
              point: item.point,
              image: item.image
            }
          })
        }
        update(params).then(res => {
          if (res.code === 200) {
            this.$message.success('提交成功')
            this.getView()
          }else{
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {

      });
    },
    handleChange(index, url){
      this.infoData[index].image = url;
    },
    handleSearch(){
      this.getView()
    },
    handleReset(){
      this.search.date = new Date();
      this.getView()
    },
    getView(){
      const year = this.search.date.getFullYear();
      const month = this.search.date.getMonth() + 1 < 10 ? `0${this.search.date.getMonth() + 1}` : this.search.date.getMonth() + 1;
      view({
        checkTime: `${year}-${month}`
      }).then(res => {
        // console.log(res)
        if (res.code === 200) {
          this.infoData = res.data.map(item=> {
            const { point, ...rest } = item;
            return {
              ...rest,
              point: item.state === 2 && item.point === 0 ? '' : item.point
            }
          });
          this.disabled = res.data.every(o => o.state === 1)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    changeBlur(e, index, max){
      const value = e.target.value;
      if (value) {
        if (isNaN(value)) {
          e.target.value = ''
          this.infoData[index].point = ''
        } else {
          if (max) {
            if (Number(value) > Number(max)) {
              this.infoData[index].point = Number(max)
            } else {
              this.infoData[index].point = Number(value)
            }
          } else {
            this.infoData[index].point = value
          }
        }
      } else {
        this.infoData[index].point = '';
        e.target.value = ''
      }
    }
  }
}
</script>

<style scoped>
.image{
  width: 100%;
}
.image1{
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>