import { actionUrl } from '@/config/public';
const manage = {
    column: [
        {
            label: '商品分类',
            prop: 'skuClassifyName',
        },
        {
            label: '商品名称',
            prop: 'skuName'
        },
        {
            label: '价格',
            prop: 'price'
        },
        {
            label: '是否有多规格',
            prop: 'isAdd',

        },
        {
            label: '商品描述',
            prop: 'des'
        },
        {
            label: '缩略图',
            prop: 'bImg',
            type: 'img'
        },
        {
            label: '二维码',
            prop: 'codeImg',
            type: 'img'
        },
        {
            label: '详情图',
            prop: 'sImg',
            type: 'img'
        },
        {
            label: '排序',
            prop: 'sort'
        }
    ],
    increaseColumn: [
        {
            label: '立项单图片',
            prop: 'startImg',
            span: 24,
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            row: true,
            rules: [{
                required: true,
                message: "请上传立项单图片",
                trigger: "blur"
            }],
        },
        {
            label: "立项单起止时间",
            type: 'datetimerange',
            prop: 'establishmentTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
                required: true,
                message: "请选择立项单起止时间",
                trigger: "blur"
            }],
        },
    ],

}
export default manage
