const column = {
    column: [
        {
            label: '岗位',
            prop: 'station',
        },
        {
            label: '姓名',
            prop: 'username',
        },
        {
            label: '性别',
            prop: 'sex',
        },
        {
            label: '年龄',
            prop: 'age'
        },
        {
            label: '经验',
            prop: 'exp',
        },
        {
            label: '应聘时间',
            prop: 'recruitTime',
        },
        {
            label: '期望薪资',
            prop: 'salaryWant',
        },
       
      
    ],
    columnIncrease: [
        {
            label: '招聘名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入招聘名称',
            rules: [{
                required: true,
                message: "请输入招聘名称",
                trigger: "blur"
            }],

        },
        // {
        //     label: '图标',
        //     prop: 'img',
        //     type: 'upload',
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     span: 24,
        //     row: true,
        //     rules: [{
        //         required: true,
        //         message: "上传图标",
        //         trigger: "blur"
        //     }],
        // },
        // {
        //     label: '排序值',
        //     prop: 'sort',
        //     span: 24,
        //     maxlength: 8,
        //     row: true,
        //     rules: [{
        //         required: true,
        //         message: "输入排序值",
        //         trigger: "blur"
        //     }],
        // }
    ]
}
export default column
