<template>
  <div class="main-content">
    <pagesHeader title="编辑" />
    <div>
      <el-descriptions column="2">
        <el-descriptions-item label="客户名称">{{dataInfo.customerName}}</el-descriptions-item>
        <el-descriptions-item label="商务">{{dataInfo.contactBusiness}}</el-descriptions-item>
        <el-descriptions-item label="联系人">{{dataInfo.contact}}</el-descriptions-item>
        <el-descriptions-item label="联系人电话">{{dataInfo.contactPhone}}</el-descriptions-item>
        <el-descriptions-item label="客户业务场景描述">{{dataInfo.des}}</el-descriptions-item>
        <el-descriptions-item label="交付时间">{{dataInfo.endTime}}</el-descriptions-item>
        <el-descriptions-item label="产品需求">{{dataInfo.function}}</el-descriptions-item>
        <el-descriptions-item label="领取时间起止">{{dataInfo.prepareStart}}{{dataInfo.prepareEnd}}</el-descriptions-item>
        <el-descriptions-item label="测试时间起止">{{dataInfo.testStart}}{{dataInfo.testEnd}}</el-descriptions-item>
        <el-descriptions-item label="质检时间起止">{{dataInfo.qualityTestStart}}{{dataInfo.qualityTestEnd}}</el-descriptions-item>
        <el-descriptions-item label="项目分类">{{dataInfo.classify}}</el-descriptions-item>
        <el-descriptions-item label="项目名称">{{dataInfo.projectName}}</el-descriptions-item>
        <el-descriptions-item label="项目业绩">{{dataInfo.projectAchievement}}</el-descriptions-item>
        <el-descriptions-item label="质检结果">{{dataInfo.projectQuality}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dataInfo.state}}</el-descriptions-item>
      </el-descriptions>
    </div>

    <div style="marginTop: 50px">
      <avue-form
        ref="form"
        v-model="form"
        :option="option"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-after="uploadAfter"
      >
      </avue-form>

      <el-form
        label-width="80px"
        v-for="(item,index) in projectWorkActualList"
        :key="index"
      >

        <div style="width: 50%">
          <el-form-item label="员工">
            <el-select
              disabled
              v-model="projectWorkActualList[index].userId"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in selctProjectWorkActualList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              >
              </el-option>
            </el-select>

          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="时间">
            <el-date-picker
              v-model="projectWorkActualList[index].time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开工时间"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="业绩">
            <el-input v-model="projectWorkActualList[index].achievement"></el-input>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="花费人日">
            <el-input v-model="projectWorkActualList[index].timeSpend"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="submit-button">
      <el-button
        size="medium"
        @click="$router.go(-1)"
      >取消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { singView, userManageUpdateDelete } from "@/api/itemEntry/sign";
import { userManageUpdate } from "@/api/itemEntry/add";
import { projectListHandle } from "@/api/selectApi/selectApi";
import dayjs from "dayjs";
import { projectQualityHandle, stateHandle } from "@/utils/tools";
import column from "./column";

export default {
  name: "edit",
  data() {
    return {
      selctProjectWorkActualList: [],
      projectWorkActualList: [],
      oldDataInfo: {},
      dataInfo: {},
      form: {
        startImg: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.increaseColumn,
      },
    };
  },
  mounted() {
    const startImg = this.findObject(this.option.column, "startImg");
    startImg.headers.Authorization = this.$store.state.user.Token;
    this.projectListHandle();
    this.userManageView();
  },
  methods: {
    async userManageView() {
      try {
        const { data } = await singView(this.$route.params.id);
        this.oldDataInfo = data;
        this.dataInfo = {
          ...data,
          projectQuality: projectQualityHandle(data.projectQuality),
          state: stateHandle(data.state),
        };
        this.projectWorkActualList = data.workTeam.map((item) => {
          return {
            achievement: item.achievement,
            timeSpend: item.workDays,
            time: [],
            userId: item.userId,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },

    async projectListHandle() {
      try {
        const { data } = await projectListHandle();
        this.selctProjectWorkActualList = data;
      } catch (error) {
        console.log(error);
      }
    },

    async handleSubmit() {
      const isStata = this.projectWorkActualList.find(
        (item) =>
          !item.userId ||
          !item.achievement ||
          item.time.length < 1 ||
          !item.timeSpend
      );
      if (isStata) {
        this.$message({
          message: "请先完善信息在保存！",
          type: "warning",
        });

        return;
      }
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          const projectWorkActualListList = this.projectWorkActualList.map(
            (item) => {
              return {
                userId: item.userId,
                achievement: item.achievement,
                timeStart: dayjs(item.time[0]).format("YYYY-MM-DD HH:mm:ss"),
                timeEnd: dayjs(item.time[1]).format("YYYY-MM-DD HH:mm:ss"),
                timeSpend: item.timeSpend,
              };
            }
          );
          const oldDataInfo = {
            ...this.oldDataInfo,
            projectWorkActualListList,
            establishmentStart: this.form.establishmentTime[0],
            establishmentEnd: this.form.establishmentTime[1],
            startImg: this.form.startImg,
          };

          userManageUpdate({
            id: this.$route.params.id,
            ...oldDataInfo,
          })
            .then((res) => {
              if (res.code === 200) {
                userManageUpdateDelete({ id: this.$route.params.id })
                  .then((res) => {
                    if (res.code === 200) {
                      this.$message({
                        message: "录入成功",
                        type: "success",
                      });
                      this.$router.push({
                        name: "manageSign",
                      });
                    }
                  })
                  .catch((err) => {
                    this.$message.error(err.data)
                  }
                  
                  );
              }
            })
            .catch((err) => console.log(err));

          console.error(error);
        }
      });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        this.form.startImg = res.path;
      });
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 50px 0;
}

.submit-button .el-button {
  padding: 10px 50px;
}

.avue-form >>> .el-input__suffix {
  display: none;
}

.el-icon-circle-plus-outline {
  position: relative;
  left: -40px;
  font-size: 30px;
}

.el-input__inner,
.el-input__inner {
  width: 100% !important;
}
</style>
