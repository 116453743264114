import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '消息标题',
            prop: 'title'
        },
        {
            label: '消息内容',
            prop: 'mes',
        },
        {
            label: '发布时间',
            prop: 'createTime'
        },
        {
            label: '用户',
            prop: 'fullName'
        },
    ],
    columnIncrease: [
        {
            label: '制度名称',
            prop: 'ruleName',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入制度名称',
            rules: [{
                required: true,
                message: "请输入制度名称",
                trigger: "blur"
            }],
        },
        {
            label: '制度规则',
            prop: 'ruleImg',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传图片",
                trigger: "blur"
            }],
        },

    ]
}
export default column
