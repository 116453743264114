export const indexOption = {
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: true, // 显示索引
    indexLabel: '序号',
    indexWidth: 100,
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align:'center',
    menuAlign:'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '测评时间',
            prop: 'checkTime'
        },
        {
            label: '人员名称',
            prop: 'fullName'
        },
        {
            label: '头像',
            prop: 'headPortrait'
        },
        {
            label: '图片',
            prop: 'image',
            type: 'img'
        },
        {
            label: '测评分数',
            prop: 'point'
        }
    ]
}