<template>
  <el-dialog
    title="新增"
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
      @submit="formSubmit"
    >
      <template slot="role">
        <el-select
          v-model="form.role"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in role"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.state == 2 ? true : false"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="createTime">
        <el-date-picker
          :picker-options="pickerOptions"
          v-model="form.createTime"
          type="date"
          placeholder="选择入职时间"
        >
        </el-date-picker>
      </template>

      <template slot="birthday">
        <el-date-picker
          :picker-options="pickerOptions"
          v-model="form.birthday"
          type="date"
          placeholder="选择生日"
        >
        </el-date-picker>
      </template>

      <template slot="userLike">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入个人爱好"
          v-model="form.userLike"
        >
        </el-input>
      </template>
      <template slot="intro">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入自我介绍"
          v-model="form.intro"
        >
        </el-input>
      </template>
      <!-- <template slot="phone">
        <input
          class="el-input__inner"
          style="height: 32px"
          placeholder="请输入 联系电话"
          maxlength="11"
          type="tel"
          v-model="form.phone"
          pattern="[0-9]"
          onkeyup="value=value.replace(/[\W]/g,'')"
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
        />
      </template> -->
    </avue-form>
  </el-dialog>
</template>

<script>
import { actionUrl } from "@/config/public";
import { userAdd } from "@/api/system/index";
import { pickBy } from "lodash";
import dayjs from "dayjs";

export default {
  name: "add",
  props: {
    dialogVisible: Boolean,
    deptChooseList: Array,
    chooseList: Array,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      form: {
        headPortrait: "",
        img: "",
        createTime: "",
        birthday: "",
      },
      role: this.$store.state.system.role,
      option: {
        labelWidth: 160,
        column: [
          {
            label: "头像",
            type: "upload",
            listType: "picture-img",
            span: 12,
            row: true,
            fileSize: 1024 * 20,
            prop: "headPortrait",
            accept: "image/png, image/jpeg",
            loadText: "附件上传中，请稍等",
            tip: "只能上传jpg/png",
            propsHttp: {
              res: "data",
              url: "url",
              name: "path",
              home: actionUrl,
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: actionUrl,
            // rules: [
            //   {
            //     required: true,
            //     message: "请上传头像",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "个人照片",
            type: "upload",
            listType: "picture-img",
            span: 12,
            row: true,
            fileSize: 1024 * 20,
            prop: "img",
            accept: "image/png, image/jpeg",
            loadText: "附件上传中，请稍等",
            tip: "只能上传jpg/png",
            propsHttp: {
              res: "data",
              url: "url",
              name: "path",
              home: actionUrl,
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: actionUrl,
            // rules: [
            //   {
            //     required: true,
            //     message: "请上传个人照片",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "部门名称",
            prop: "deptId",
            type: "select",
            dicData: this.deptChooseList,
            span: 12,
            props: {
              label: "deptName",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择部门名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "岗位",
            prop: "station",
            maxlength: 30,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入岗位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户名",
            prop: "username",
            maxlength: 15,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入用户名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密码",
            prop: "password",
            type: "password",
            maxlength: 30,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入密码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "真实姓名",
            prop: "fullName",
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入真实姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "性别",
            prop: "sex",
            type: "select",
            maxlength: 30,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择性别",
            //     trigger: "blur",
            //   },
            // ],
            dicData: [
              {
                id: "男",
                name: "男",
              },
              {
                id: "女",
                name: "女",
              },
            ],
            span: 12,
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "生日",
            prop: "birthday",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd",
            },
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入生日",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "联系电话",
            prop: "phone",
            maxlength: 11,
            slot: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入联系电话",
            //     trigger: "blur",
            //   },
            //   {
            //     pattern: /^1\d{10}/,
            //     message: "请输入正确手机号",
            //   },
            // ],
          },
          {
            label: '地址',
            prop: 'address',
            maxlength: 30,
            showWordLimit: true,
          },
          {
            label: "微信号",
            prop: "wechatNum",
            maxlength: 20,
            slot: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入微信号",
            //     trigger: "blur",
            //   },
            //   {
            //     pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            //     message:
            //       "可以使用6-20个字母、数字、下划线和减号,必须以字母开头（字母不区分大小写）,不能设置中文",
            //   },
            // ],
          },
          {
            label: "QQ号",
            prop: "qqNum",
            slot: true,
            maxlength: 11,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入QQ号",
            //     trigger: "blur",
            //   },
            //   {
            //     pattern: /[1-9][0-9]{4,}/,
            //     message: "请输入正确的QQ号",
            //   },
            // ],
          },
          {
            label: "紧急联系人",
            prop: "sosName",
            maxlength: 15,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入紧急联系人",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "紧急联系人手机号",
            prop: "sosPhone",
            maxlength: 11,
            slot: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入紧急联系人手机号",
            //     trigger: "blur",
            //   },
            //   {
            //     pattern: /^1\d{10}/,
            //     message: "请输入正确手机号",
            //   },
            // ],
          },
          {
            label: "毕业院校",
            prop: "school",
            maxlength: 20,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入毕业院校",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "专业",
            prop: "schoolMajor",
            maxlength: 20,
            showWordLimit: true,
          },
          {
            label: "职级",
            prop: "rankId",
            type: "select",
            dicData: this.chooseList,
            span: 12,
            props: {
              label: "levelName",
              value: "id",
            },
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入职级",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "学历",
            prop: "education",
            type: "select",
            dicData: [
              {
                id: "1",
                name: "高中",
              },
              {
                id: "2",
                name: "专科",
              },
              {
                id: "3",
                name: "本科",
              },
              {
                id: "4",
                name: "硕士",
              },
            ],
            span: 12,
            props: {
              label: "name",
              value: "id",
            },
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入学历",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "邮箱",
            prop: "email",
            slot: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入邮箱",
            //     trigger: "blur",
            //   },
            //   {
            //     pattern:
            //       /^([\.a-zA-Z0-9_-])+@[\.a-zA-Z0-9_-]+(\.[\.a-zA-Z0-9_-]+)+$/,
            //     message: "请输入正确的邮箱",
            //   },
            // ],
          },
          {
            label: "个人爱好",
            prop: "userLike",
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入个人爱好",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "自我介绍",
            prop: "intro",
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入自我介绍",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "入职时间",
            prop: "createTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            mock: {
              type: "datetime",
              format: "yyyy-MM-dd",
            },
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择入职时间",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "所属角色",
            prop: "roleIds",
            type: "select",
            dicData: this.$store.state.system.role,
            span: 12,
            multiple: true,
            props: {
              label: "name",
              value: "id",
            },
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择所属角色",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "是否在职",
            prop: "isWork",
            type: "radio",
            dicData: [
              { label: "在职", value: 1 },
              { label: "离职", value: 2 },
            ],
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择是否在职",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "是否参加业绩排行",
            prop: "isJoin",
            type: "radio",
            dicData: [
              { label: "参加", value: 1 },
              { label: "不参加", value: 2 },
            ],
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择是否参加业绩排行",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            dicData: [
              { label: "启用", value: 1 },
              { label: "禁用", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done, loading, column) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.headPortrait = res.image;
      this.$nextTick(() => {
        // this.form.headPortrait = res.path;
        // this.form.img = res.path;
        console.log(column);
        if (column.prop === "headPortrait") {
          this.form.headPortrait = res.path;
          console.log("headPortrait");
        }
        if (column.prop === "img") {
          this.form.img = res.path;
          console.log("img");
        }
      });
    },
    handleClose() {
      // this.$confirm("确认关闭？").then((res) => {
      this.$root.eventHub.$emit("changeAdd", false);
      // });
    },
    formSubmit(form, done) {
      const newForm = {
        ...form,
        birthday: form.birthday
          ? dayjs(form.birthday).format("YYYY-MM-DD")
          : "",
        createTime: form.createTime
          ? dayjs(form.createTime).format("YYYY-MM-DD")
          : "",
      };
      console.log("newForm", newForm);

      let that = this;
      // if (
      //   !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
      //     form.phone
      //   )
      // ) {
      //   this.$message.error("手机号错误");
      //   done();
      //   return false;
      // }
      const addData = {
        ...newForm,
        roleIds: form.roleIds ? form.roleIds.toString() : "",
      };
      userAdd(pickBy(addData, (value) => value !== ""))
        .then((res) => {
          // 获取数据成功后的其他操作
          // console.log(res);
          if (res.code === 200) {
            that.$message.success(res.msg);
            this.$root.eventHub.$emit("changeAdd", true);
            done();
          } else {
            that.$message.error(res.msg);
            done();
          }
        })
        .catch((err) => {
          that.$message.error(err.msg);
          done();
        });
    },
  },
};
</script>

<style scoped>
</style>
