<template>
    <div class="main-content">
        <pagesHeader title="新闻资讯" />
        <Search @changeSearch="changeSearch" @clickIncrease="clickIncrease"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">

                <template slot="menu" slot-scope="{ row }">
                    <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
                    <el-button size="small" type="text" @click="changeDelete(row.id)">删除</el-button>
                </template>
        </avue-crud>
        <Increase v-if="increaseType.type" :increaseType="increaseType"/>
    </div>
</template>

<script>
    import Search from "./search";
    import column from "./column";
    import Increase from "./increase"
    import { actionUrl } from '@/config/public';
    import { topicList,topicDelete,topicUpdate } from "@/api/topic/manage"
    export default {
        name: "classify",
        components: {
            Search,
            Increase
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
              increaseType: {
                    type: false,
                    id: ''
                },
              uOptions: {
                action: actionUrl,
                headers: {
                  Authorization: this.$store.state.user.Token
                },
                customConfig: {},
                props: {
                  url: 'path',
                  res: 'data',
                  name: 'path'
                }
              },
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeInfoClassify', (n)=>{
                this.increaseType = {
                    type: false,
                    id: ''
                }
                if(n){
                    this.GET_infoList();
                }
            })
        },
        methods: {
            onLoad(){
                this.GET_infoList();
            },
            GET_infoList(){
              topicList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name
                }).then(res=>{
                    if(res.code===200){
                        // res.data.records.forEach(item => {
                        //     item.show = item.isShow == 1 ? true : false
                        // })
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            switchChange(id,i){
                // console.log(id,i)
              topicUpdate({
                    id: id,
                    isShow: i ? 1 : 2
                }).then(res=>{
                    if(res.code===200){
                        this.GET_infoList();
                        this.$message.success(res.msg)
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.increaseType = {
                    type: true,
                    id: id
                }
            },
            changeDelete(id){
                // console.log("删除",id)
                this.$confirm('删除分类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                  topicDelete(id).then(res=>{
                        if(res.code===200){
                            this.GET_infoList();
                            this.$message.success(res.msg)
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                }).catch(() => {
                });
            },
            changeSearch(e){
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_infoList();
            },
            clickIncrease(){
                this.increaseType = {
                    type: true,
                    id: ''
                }
            },
          uploadDelete() {
            return this.$confirm(`是否确定移除？`);
          },
          uploadError(error, column) {
            this.$message.error('上传失败');
            // console.log(error, column)
          },
          uploadAfter(res, done) {
            done();
            console.log(res)
            this.$nextTick(() => {
              this.form.icon = res.path;
            });
          },

        }
    }
</script>

<style scoped>

</style>
