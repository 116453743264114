<template>
  <div class="main-content">
    <pagesHeader title="员工手册" />
    <avue-form ref="form"
               @submit="handleSave"
               v-model="form"
               :option="formOption">
      <template slot="content">
        <avue-ueditor
            v-model="form.content"
            v-bind="options"
        ></avue-ueditor>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { view, update } from "@/api/news/handbook"
import {actionUrl} from "@/config/public";
export default {
  name: "index",
  data(){
    return{
      form: {},
      formOption: {
        submitText: '保存',
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: '',
            prop: 'content',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入商品详情",
              trigger: "blur"
            }]
          }
        ]
      },
      options: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        customConfig: {},
        propsHttp: {
          url: "path",
          res: "data",
        },
      },
    }
  },
  mounted() {
    this.getView();
  },
  methods: {
    getView(){
      view().then(res => {
        if (res.code === 200) {
          this.form = res.data
        }
      })
    },
    handleSave(form,done){
      update(form).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          done()
        }else{
          this.$message.error(res.msg)
          done()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>