const manage = {
    // column: [
    //     {
    //         label: '商品分类',
    //         prop: 'skuClassifyName',
    //     },
    //     {
    //         label: '商品名称',
    //         prop: 'skuName'
    //     },
    //     {
    //         label: '价格',
    //         prop: 'price'
    //     },
    //     {
    //         label: '是否有多规格',
    //         prop: 'isAdd',

    //     },
    //     {
    //         label: '商品描述',
    //         prop: 'des'
    //     },
    //     {
    //         label: '缩略图',
    //         prop: 'bImg',
    //         type: 'img'
    //     },
    //     {
    //         label: '二维码',
    //         prop: 'codeImg',
    //         type: 'img'
    //     },
    //     {
    //         label: '详情图',
    //         prop: 'sImg',
    //         type: 'img'
    //     },
    //     {
    //         label: '排序',
    //         prop: 'sort'
    //     }
    // ],
    increaseColumn: [
        {
            label: "用户名称",
            prop: "userIds",
            type: 'select',
            showWordLimit: true,
            dicData: [],
            span: 14,
            rules: [
                {
                    required: true,
                    message: "请选择用户名称",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "消息标题",
            prop: "title",
            maxlength: 30,
            span: 14,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入消息标题",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "消息内容",
            prop: "message",
            maxlength: 200,
            span: 14,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入消息内容",
                    trigger: "blur",
                },
            ],
        },
       
    ],

}
export default manage
