<template>
  <div class="main-content">
    <pagesHeader title="编辑简历" />
    <avue-form
      ref="form"
      v-model="form"
      :option="option"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
      :upload-preview="uploadPreview"
    >

      <template slot="station">
        <el-select
          v-model="form.station"
          placeholder="请选择 岗位"
          filterable
        >
          <el-option
            v-for="item in stationList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </template>

      <template slot="sex">
        <el-select
          v-model="form.sex"
          placeholder="请选择 性别"
        >
          <el-option
            v-for="item in sexList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </template>

      <template slot="recruitTime">
        <el-date-picker
          v-model="form.recruitTime"
          type="date"
          placeholder="请选择 应聘时间"
        >
        </el-date-picker>
      </template>

    </avue-form>

    <div class="submit-button">
      <el-button
        size="medium"
        @click="$router.go(-1)"
      >取消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit(form)"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { recruitTypeList2 } from "@/api/selectApi/selectApi";
import { manageUpdate, manageView } from "@/api/recruit/manage";
import column from "./column";
import dayjs from "dayjs";

export default {
  data() {
    return {
      accessory: [],
      form: {
        file: "",
        fileUrl: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 160,
        column: column.column,
      },
      stationList: [],
      sexList: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],
    };
  },

  mounted() {
    this.manageView();
    this.recruitTypeList2();
  },
  methods: {
    async manageView() {
      try {
        const { data } = await manageView(this.$route.params.id);
        this.form = {
          ...data,
          fileUrl: data.file,
          file: data.fileName,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async recruitTypeList2() {
      try {
        const { data } = await recruitTypeList2();
        this.stationList = data;
      } catch (error) {
        console.log(error);
      }
    },
    handleSubmit(form) {
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          const newData = {
            ...form,
            recruitTime: dayjs(form.recruitTime).format("YYYY-MM-DD"),
            file: form.fileUrl,
            fileName: form.file,
          };
          manageUpdate(newData)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$router.push({
                  name: "recruitManageList",
                });
              } else {
                this.$message.error(res.msg);
              }
              done();
            })
            .catch((err) => {
              this.$message.error(err.msg);
              done();
            });
        }
      });
    },
    uploadBefore(file, done, loading) {
      if (
        file.type == "application/pdf" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type == "application/msword"
      ) {
        done();
        this.form.file = file.name;
      } else {
        this.$message.warning("请选择指定格式的文件");
        loading();
      }
    },
    uploadPreview(file, column, done) {
      window.open(this.form.fileUrl, "_blank");
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      console.log(res, column);
      this.$nextTick(() => {
        this.form.fileUrl = res.path;
      });
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 50px 0;
}

.submit-button .el-button {
  padding: 10px 50px;
}

.avue-form >>> .el-input__suffix {
  display: none;
}

.el-icon-circle-plus-outline {
  position: relative;
  top: 60px;
  left: 50%;
  font-size: 40px;
}
.el-icon-remove-outline {
  position: relative;
  top: 60px;
  left: 51%;
  font-size: 40px;
}

.el-input__inner,
.el-input__inner {
  width: 100% !important;
}
</style>
