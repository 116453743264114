import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';

// view 内容
// index首页
import indexHome from '@/view/index/index';

// user 用户管理
import userManage from '@/view/user/user/list';


// itemEntry 项目录入
import manageList from '@/view/itemEntry/list/manage';
import manageAdd from '@/view/itemEntry/add/manage';
import manageEdit from '@/view/itemEntry/edit/manage';
import manageSign from '@/view/itemEntry/sign/manage';
import manageSignEdit from '@/view/itemEntry/sign/edit/manage'
import manageSignDetails from '@/view/itemEntry/details/manage'
import registrationRecord from '@/view/itemEntry/registrationRecord/manage'


// 文章资讯
import newsClassify from '@/view/news/classify/classify';
import newsManage from '@/view/news/manage/manage';
import newsMessageAddPush from '@/view/news/push/manage';
import newsMessagePushList from '@/view/news/pushList/manage';
import newsHandbook from '@/view/news/handbook/index';

// 榜单管理
import topClassify from '@/view/top/classify/classify';
import topManage from '@/view/top/manage/manage';
import performanceCalendar from '@/view/top/performanceCalendar/manage'

// 轮播管理
import bannerRedList from '@/view/banner/red/manage'
import bannerBlackList from '@/view/banner/black/manage'
import bannerBlackListDetails from '@/view/banner/black/details'
import bannerMouthList from '@/view/banner/mouth/manage'

// system 组织架构
import systemUser from '@/view/system/user';
import systemUserWorkView from '@/view/system/workView/manage'
import systemRole from '@/view/system/role';
import systemMenu from '@/view/system/menu/list';
import systemExamine from '@/view/system/examine/index';

// config 基础设置
import configBase from '@/view/config/manage/service'
import configProjectClassifyList from '@/view/config/projectClassify/manage'
import configLogList from '@/view/config/logList/manage'

// 荣誉列表
import honorLabelList from '@/view/honor/tabel/manage'

//绩效考评
import evaluateQuestionList from '@/view/evaluate/question/list/manage'
import evaluateQuestionEdit from '@/view/evaluate/question/edit/manage'
import evaluateAnswerList from '@/view/evaluate/answer/list/manage'
import evaluateAnswerDetails from '@/view/evaluate/answer/details/manage'
import evaluateLeaderList from '@/view/evaluate/leader/manage'
import evaluateWorkList from '@/view/evaluate/work/manage'
import evaluateTestList from '@/view/evaluate/test/manage'
import evaluateQuestionType from '@/view/evaluate/type/manage'
import evaluationRecord from '@/view/evaluate/evaluationRecord/manage'

//荣誉管理
import honorSendList from '@/view/honor/send/manage'
import honorTabelList from '@/view/honor/tabel/manage'

//简历管理
import recruitTypeList from '@/view/recruit/typeList/manage'
import recruitManageList from '@/view/recruit/manageList/manage'
import recruitManageAdd from '@/view/recruit/manageAdd/manage'
import recruitManageEdit from '@/view/recruit/manageEdit/manage'

// 每月测评
import checkUpload from '@/view/check/upload/index'
import checkManage from '@/view/check/manage/index'

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [
				{
					path: '/',
					name: 'indexHome',
					component: manageList
				},
				{
					path: '/project/manage/list',
					name: 'manageList',
					component: manageList
				},
				{
					path: '/project/manage/add',
					name: 'manageAdd',
					component: manageAdd
				},
				{
					path: '/project/manage/edit/:id',
					name: 'manageEdit',
					component: manageEdit
				},
				{
					path: '/project/manage/details/:id',
					name: 'manageSignDetails',
					component: manageSignDetails
				},
				{
					path: '/project/manage/registrationRecord/:id',
					name: 'registrationRecord',
					component: registrationRecord
				},
				{
					path: '/project/manage/sign',
					name: 'manageSign',
					component: manageSign
				},
				{
					path: '/project/manage/sign/edit/:id',
					name: 'manageSignEdit',
					component: manageSignEdit
				},

				// ------------------------------------------------------ 组织结构
				{
					path: '/system/user',
					name: 'systemUser',
					component: systemUser
				},
				{
					path: '/system/user/workView/:id',
					name: 'systemUserWorkView',
					component: systemUserWorkView
				},
				{
					path: '/system/role',
					name: 'role',
					component: systemRole
				},
				{
					path: '/system/rank/list',
					name: 'userManage',
					component: userManage
				},
				{
					path: '/system/dept/list',
					name: 'systemMenu',
					component: systemMenu
				},
				{
					path: '/system/examine',
					name: 'systemExamine',
					component: systemExamine
				},
				// 文章资讯
				{
					path: '/honor/label/list',
					name: 'honorLabelList',
					component: honorLabelList
				},
				// 荣誉列表
				{
					path: '/news/classify/list',
					name: 'newsClassify',
					component: newsClassify
				},
				{
					path: '/news/manage/list',
					name: 'newsManage',
					component: newsManage
				},
				{
					path: '/news/message/push/:id',
					name: 'newsMessageAddPush',
					component: newsMessageAddPush
				},
				{
					path: '/news/message/push',
					name: 'newsMessagePushList',
					component: newsMessagePushList
				},
				{
					path: '/news/handbook',
					name: 'newsHandbook',
					component: newsHandbook
				},
				//基本设置
				{
					path: '/config/base/set',
					name: 'configBase',
					component: configBase
				},
				{
					path: '/config/projectClassify/list',
					name: 'configProjectClassifyList',
					component: configProjectClassifyList
				},
				{
					path: '/config/log/list',
					name: 'configLogList',
					component: configLogList
				},
				// 榜单管理
				{
					path: '/top/achievement/list',
					name: 'topAchievement',
					component: topClassify
				},
				{
					path: '/top/complete/list',
					name: 'topComplete',
					component: topManage
				},
				{
					path: '/performanceCalendar/list',
					name: 'performanceCalendar',
					component: performanceCalendar
				},
				// 轮播管理
				{
					path: '/banner/red/list',
					name: 'bannerRedList',
					component: bannerRedList
				},
				{
					path: '/banner/black/list',
					name: 'bannerBlackList',
					component: bannerBlackList
				},
				{
					path: '/banner/black/list/details/:id',
					name: 'bannerBlackListDetails',
					component: bannerBlackListDetails
				},
				{
					path: '/banner/mouth/list',
					name: 'bannerMouthList',
					component: bannerMouthList
				},
				// 绩效考评
				{
					path: '/evaluate/question/list',
					name: 'evaluateQuestionList',
					component: evaluateQuestionList
				},
				{
					path: '/evaluate/question/type',
					name: 'evaluateQuestionType',
					component: evaluateQuestionType
				},
				{
					path: '/evaluate/question/list/edit/:id',
					name: 'evaluateQuestionEdit',
					component: evaluateQuestionEdit
				},
				{
					path: '/evaluate/answer/list',
					name: 'evaluateAnswerList',
					component: evaluateAnswerList
				},
				{
					path: '/evaluate/answer/list/details/:id',
					name: 'evaluateAnswerDetails',
					component: evaluateAnswerDetails
				},
				{
					path: '/evaluate/leader/list',
					name: 'evaluateLeaderList',
					component: evaluateLeaderList
				},
				{
					path: '/evaluate/work/list',
					name: 'evaluateWorkList',
					component: evaluateWorkList
				},
				{
					path: '/evaluate/test/list',
					name: 'evaluateTestList',
					component: evaluateTestList
				},
				{
					path: '/evaluate/evaluation/record',
					name: 'evaluationRecord',
					component: evaluationRecord
				},
				// 荣誉管理
				{
					path: '/honor/send/list',
					name: 'honorSendList',
					component: honorSendList
				},
				{
					path: '/honor/tabel/list',
					name: 'honorTabelList',
					component: honorTabelList
				},
				// 简历管理
				{
					path: 'recruit/type/list',
					name: 'recruitTypeList',
					component: recruitTypeList
				},
				{
					path: 'recruit/manage/list',
					name: 'recruitManageList',
					component: recruitManageList
				},
				{
					path: '/recruit/manage/add',
					name: 'recruitManageAdd',
					component: recruitManageAdd
				},
				{
					path: '/recruit/manage/edit/:id',
					name: 'recruitManageEdit',
					component: recruitManageEdit
				},
				// 每月测评
				{
					path: '/check/upload',
					name: 'checkUpload',
					component: checkUpload
				},
				{
					path: '/check/manage',
					name: 'checkManage',
					component: checkManage
				}
			]
		}
	]
})
