import dayjs from 'dayjs'

export function formatterTime(value) {
    console.log(value)
    if (value) {
        dayjs(value).format('YYYY-MM-DD')
    }
    return ''
}
export function isStateHandle(value) {
    if (value) {
        if (value === 1) {
            return '员工未填写'
        }
        if (value === 2) {
            return '员工已填写但为复核'
        }
        if (value === 3) {
            return '组长已经复核但总监为复核'
        }
        if (value === 4) {
            return '总监已经复核'
        }
    }

    return ''
}

export function projectQualityHandle(value) {
    if (value) {
        if (value === '1') {
            return '卓越'
        }
        if (value === '2') {
            return '优秀'
        }
        if (value === '3') {
            return '合格'
        }
        if (value === '4') {
            return '事故'
        }
        if (value === '5') {
            return '重大事故'
        }
        if (value === '6') {
            return '烂尾'
        }
    }

    return ''

}

export function stateHandle(value) {
    if (value) {
        if (value === 7) {
            return '未成交'
        }
        if (value === 1) {
            return '待成交'
        }
        if (value === 2) {
            return '待立项'
        }
        if (value === 3) {
            return '开发中'
        }
        if (value === 4) {
            return '待测试'
        }
        if (value === 5) {
            return '待质检'
        }
        if (value === 6) {
            return '完工'
        }
    }

    return ''
}

