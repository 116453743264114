import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '名称',
            prop: 'name'
        },
        {
            label: '图片',
            prop: 'img',
            type: 'upload',
            listType: 'picture-img',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true
        },
        {
            label: '阅读量',
            prop: 'readNum'
        },
        {
            label: '排序值',
            prop: 'sort'
        },
    ],
    columnIncrease: [
        {
            label: '名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入文章名称',
            rules: [{
                required: true,
                message: "请输入文章名称",
                trigger: "blur"
            }],
        },
        {
            label: '图片',
            prop: 'img',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传图片",
                trigger: "blur"
            }],
        },
        {
            label: '内容',
            prop: 'news',
            span: 24,
            row: true,
            maxlength: 30,
            rules: [{
                required: true,
                message: "请输入文章咨询",
                trigger: "blur"
            }],
        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            maxlength: 8,
            row: true,
            rules: [{
                required: true,
                message: "请输入排序值",
                trigger: "blur"
            }],
        }
    ]
}
export default column
