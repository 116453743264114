import { Delete, get, post, put } from '../axios'

// 绩效考评-列表
export const questionList = params => get(`/web/evaluate/question/list`,params);

// 文章分类-新增
export const questionAdd = params => post(`/web/evaluate/question/add`,params);

// 文章分类-查看
export const questionView = params => get(`/web/evaluate/question/view/${params}`);

// 文章分类-修改
export const questionUpdate = params => put(`/web/evaluate/question/update`,params);

// 文章分类-删除
export const questionDelete = params => Delete(`/web/evaluate/question/delete/${params}`);


