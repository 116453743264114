const column = {
    increaseColumn: [
        {
            label: '年度业绩目标',
            prop: 'achievementGoal',
            span: 24,
            rules: [{
                required: true,
                message: '年度业绩目标不能为空且不为负数',
                trigger: 'blur'
            }]
        },
        {
            label: '奖金比例',
            prop: 'present',
            span: 24,
            rules: [{
                required: true,
                message: '奖金比例不能为空且在10和0之间',
                trigger: 'blur'
            }]
        },
        {
            label: 'appid',
            prop: 'appid',
            span: 24,
            rules: [{
                required: true,
                message: 'appid不能为空',
                trigger: 'blur'
            }]
        },
        {
            label: 'secret',
            prop: 'secretKey',
            span: 24,
            rules: [{
                required: true,
                message: 'secretKey不能为空',
                trigger: 'blur'
            }]
        },
        {
            label: '公司简介',
            prop: 'company',
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "请输入公司简介",
                trigger: "blur"
            }],
        },
    ]
}
export default column
