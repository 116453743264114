<template>
    <el-dialog
            title="用户角色配置"
            :visible.sync="dialogVisible"
            width="60%"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <avue-form v-model="form" :option="option">
            <template slot="role">
                <el-select v-model="form.role" multiple placeholder="请选择">
                    <el-option
                            v-for="item in role"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            :disabled="item.state == 2 ? true : false">
                    </el-option>
                </el-select>
            </template>
        </avue-form>

        <span slot="footer" class="dialog-footer">
                <el-button size="small" icon="el-icon-circle-check" type="primary" @click="handleSubmit">确 定</el-button>
                <el-button size="small" icon="el-icon-circle-close" @click="handleClose">取 消</el-button>
            </span>
    </el-dialog>
</template>

<script>
    import { roleView,roleUpdate } from "@/api/system/index";
    export default {
        name: "role",
        props: {
            dialogVisible: Boolean,
            choiceId: String
        },
        data(){
            return{
                role: this.$store.state.system.role,
                form:{},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [{
                        label: '角色配置',
                        prop: 'role',
                        type: "cascader",
                        span: 24,
                        dicData: this.$store.state.system.role,
                        props: {
                            label: 'name',
                            value: 'id'
                        },
                        multiple: true
                    }]
                }
            }
        },
        mounted() {
            roleView(this.choiceId).then(res => {
                // console.log(res)
                this.form.role = res.data;
            }).catch(err =>{
                this.$message.error(err.msg)
            })
        },
        methods:{
            handleClose(){
                // this.$confirm('确认关闭？')
                //     .then(res => {
                        this.$root.eventHub.$emit('changeRole', false)
                    // })
            },
            handleSubmit(){
                // console.log(this.form.role)
                roleUpdate({
                    id: this.choiceId,
                    roleIds: this.form.role.toString()
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeRole', false)
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err =>{
                    this.$message.success(err.msg)
                })
            }
        }
    }
</script>

<style scoped>

</style>
