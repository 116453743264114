import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '名称',
            prop: 'name'
        },
        {
            label: '排序',
            prop: 'sort'
        },
        {
            label: 'icon',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res:'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传分类icon",
                trigger: "blur"
            }],
        },
    ],
    columnIncrease: [
        {
            label: '名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 4,
            placeholder: '最多输入4个字',
            rules: [{
                required: true,
                message: "输入话题分类名称",
                trigger: "blur"
            }],

        },
        {
            label: 'icon',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res:'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传分类icon",
                trigger: "blur"
            }],
        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "输入排序值",
                trigger: "blur"
            }],
        }
    ]
}
export default column
