<template>
  <div class="search">
    <el-select
      v-model="form.userId"
      placeholder="请选择员工"
      filterable
      style="margin-right:10px"
    >
      <el-option
        v-for="item in selctProjectWorkActualList"
        :key="item.id"
        :label="item.fullName"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="form.projectId"
      placeholder="请选择项目"
      filterable
      class="margin"
    >
      <el-option
        v-for="item in selectProjectNameList"
        :key="item.id"
        :label="item.projectName"
        :value="item.id"
      >
      </el-option>
    </el-select>

    <el-button
      type="primary"
      @click="searchQuery"
      style="margin-bottom: 10px"
    >查询</el-button>
    <el-button
      type="info"
      @click="searchReset"
      style="margin-bottom: 10px"
    >重置</el-button>

  </div>
</template>

<script>
import {
  projectListHandle,
  projectNameHandle,
} from "@/api/selectApi/selectApi";

export default {
  name: "search",
  props: {},
  data() {
    return {
      form: {
        userId: "",
        projectId: "",
      },
      selectProjectNameList: [],
      selctProjectWorkActualList: [],
    };
  },
  mounted() {
    this.projectNameHandle();
    this.projectListHandle();
  },
  methods: {
    async projectNameHandle() {
      try {
        const { data } = await projectNameHandle();
        this.selectProjectNameList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async projectListHandle() {
      try {
        const { data } = await projectListHandle();
        // console.log("data", data);
        this.selctProjectWorkActualList = data;
      } catch (error) {
        console.log(error);
      }
    },
    searchQuery() {
      this.$emit("changeSearch", this.form);
    },
    searchReset() {
      this.form = {
        userId: "",
        projectId: "",
      };
      this.$emit("clickIncrease");
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}
.search > .el-input {
  width: 200px;
}
.margin {
  margin-bottom: 10px;
  margin-right: 10px;
}

/* ::v-deep .margin .el-input__inner {
  width: 60px;
} */
</style>
