const manage = {
    increaseColumn: [
        // {
        //     label: "总监",
        //     prop: "reCheckUserIds",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     rules: [
        //         {
        //             required: true,
        //             message: "请输入总监",
        //             trigger: "blur",
        //         },
        //     ],
        // },
        // {
        //     label: "组长",
        //     prop: "checkUserIds",
        //     type: 'select',
        //     showWordLimit: true,
        //     dicData: [],
        //     rules: [
        //         {
        //             required: true,
        //             message: "请输入组长",
        //             trigger: "blur",
        //         },
        //     ],
        // },
        // {
        //     label: "员工",
        //     prop: "userIds",
        //     maxlength: 15,
        //     showWordLimit: true,
        //     // rules: [
        //     //     {
        //     //         required: true,
        //     //         message: "请输入员工",
        //     //         trigger: "blur",
        //     //     },
        //     // ],

        // },
        {
            label: "评测名称",
            prop: "name",
            maxlength: 15,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入评测名称",
                    trigger: "blur",
                },
            ],

        },


    ],

}
export default manage
