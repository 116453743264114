<template>
  <div class="main-content">
    <pagesHeader title="员工管理" />
    <div class="table-content">
      <avue-crud
        :data="data"
        :option="option"
        :search.sync="search"
        :page.sync="pageSync"
        @on-load="onLoad"
        ref="crud"
      >

        <template slot="headPortrait" slot-scope="{ row }">
          <div style="display: flex;align-items: center;justify-content: center">
            <el-image
                style="width: 44px; height: 44px; display: block"
                :src="row.headPortrait"
                :preview-src-list="[row.headPortrait]">
            </el-image>
          </div>
        </template>

        <template
          slot="resume"
          slot-scope="{row}"
        >
          <!-- <el-link
            type="primary"
          @click.stop="clickMenu('viewResume',row)"
          >查看简历</el-link>
        </template> -->

          <el-link
            type="primary"
            @click.stop="workListView(row.id)"
          >查看简历</el-link>
        </template>

        <template slot="menuLeft">
          <div style="display: flex;align-items: center;justify-content: center;margin-bottom: 10px">
          <el-button
            type="primary"
            size="medium"
            @click.stop="clickMenu('add')"
          >新增账号</el-button>
          <div style="margin-right: 10px">
            <el-input
                v-model="search.fullName"
                placeholder="请输入真实姓名"
            ></el-input>
          </div>
            <div style="margin-right: 10px">
            <el-input
                v-model="search.phone"
                placeholder="请输入手机号"
            ></el-input>
          </div>
          <div style="margin-right: 10px">
            <el-select size="medium" v-model="search.isWork" clearable>
              <el-option label="是否在职" value="" />
              <el-option label="在职" :value="1" />
              <el-option label="离职" :value="2" />
            </el-select>
          </div>
          <el-button
            type="primary"
            size="medium"
            @click="searchQuery"
          >查询</el-button>
          <el-button
            type="info"
            size="medium"
            @click="searchReset"
          >重置</el-button>
          </div>
        </template>

        <template slot="menuRight">
          <div style="display:flex">

            <el-upload
              :headers="headers"
              :action="action"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeUpload"
              multiple
              type="primary"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              :limit="3"
              :file-list="fileList"
            >
              <el-button>导入</el-button>
            </el-upload>

            <el-button @click="aaa">导出</el-button>

            <el-button
              @click="onDownloadClick"
              type="link"
            >下载模板</el-button>

          </div>
        </template>

        <template
          slot="menu"
          slot-scope="{row}"
        >
          <el-button
            style="margin-left:10px;"
            size="small"
            type="text"
            @click.stop="clickMenu('edit',row)"
          >修改</el-button>

          <el-button
            style="margin-left:10px;"
            size="small"
            type="text"
            @click.stop="clickMenu('reset',row)"
          >密码重置</el-button>

          <el-button
            style="margin-left:10px;"
            size="small"
            type="text"
            @click.stop="clickMenu('auth',row)"
          >授权</el-button>

          <el-button
            style="margin-left:10px;"
            size="small"
            type="text"
            @click.stop="clickMenu('cel',row)"
          >删除</el-button>
        </template>

        <!-- <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary"
                               icon="el-icon-circle-plus-outline"
                               size="small"
                               v-if="type=='add'"
                               @click="$refs.crud.rowSave()"
                    >保存</el-button>
                    <el-button type="primary"
                               icon="el-icon-check"
                               size="small"
                               v-if="type=='edit'"
                               @click="$refs.crud.rowUpdate()"
                    >修改</el-button>
                    <el-button
                               icon="el-icon-circle-close"
                               size="small"
                               plain
                               @click="$refs.crud.closeDialog()"
                    >取消</el-button>
                </template> -->

      </avue-crud>
    </div>

    <Role
      v-if="dialogVisible_role"
      :dialogVisible="dialogVisible_role"
      :choiceId="choiceId"
    />
    <Edit
      v-if="dialogVisible_edit"
      :dialogVisible="dialogVisible_edit"
      :choiceId="choiceId"
      :deptChooseList="deptChooseList"
      :chooseList="chooseList"
    />
    <Add
      v-if="dialogVisible_add"
      :dialogVisible="dialogVisible_add"
      :deptChooseList="deptChooseList"
      :chooseList="chooseList"
    />
    <LookView
      v-if="dialogVisible_view"
      :dialogVisible="dialogVisible_view"
      :choiceId="choiceId"
    />

    <ViewResume
      v-if="dialogVisible_viewResume"
      :dialogVisible="dialogVisible_viewResume"
    />

  </div>
</template>

<script>
import Role from "@/components/system/user/role";
import Edit from "@/components/system/user/edit";
import Add from "@/components/system/user/add";
import LookView from "@/components/system/user/lookview";
import ViewResume from "@/components/system/user/viewResume";

import {
  userlist,
  userDelete,
  passwordReset,
  userDeptChooseList,
  userChooseList,
} from "@/api/system/index";
import { mapActions } from "vuex";

export default {
  name: "role",
  components: {
    Add,
    Role,
    Edit,
    LookView,
    ViewResume,
  },
  data() {
    return {
      // 部门列表
      deptChooseList: [],
      // 职级列表
      chooseList: [],
      Buttons: [], //表格按钮
      search: {
        fullName: "",
        phone: "",
        isWork: "",
      },
      data: [],
      option: {
        index: true,
        indexLabel: "序号",
        indexWidth: 50,
        emptyText: "暂无数据",
        stripe: false,
        showHeader: true,
        border: true,
        align: "center",
        menuAlign: "center",
        columnBtn: false, //列显隐
        refreshBtn: false, //刷新
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            label: "部门名称",
            prop: "dept",
          },
          {
            label: "岗位",
            prop: "station",
          },
          {
            label: "头像",
            prop: "headPortrait",
          },
          {
            label: "真实姓名",
            prop: "fullName",
          },
          {
            label: "年龄",
            prop: "age",
          },
          {
            label: "生日",
            prop: "birthday",
          },

          {
            label: "学历",
            prop: "education",
            type: "select",
            dicData: [
              {
                value: "1",
                label: "高中",
              },
              {
                value: "2",
                label: "专科",
              },
              {
                value: "3",
                label: "本科",
              },
              {
                value: "4",
                label: "硕士",
              },
            ],
          },
          {
            label: "邮箱",
            prop: "email",
          },
          {
            label: "是否在职",
            prop: "isWork",
            type: "select",
            dicData: [
              {
                value: 1,
                label: "在职",
              },
              {
                value: 2,
                label: "离职",
              },
            ],
          },

          {
            label: "手机号",
            prop: "phone",
          },
          {
            label: "QQ号",
            prop: "qqNum",
          },
          {
            label: "微信号",
            prop: "wechatNum",
          },
          {
            label: "职级",
            prop: "rank",
          },
          // {
          //   label: "毕业院校",
          //   prop: "school",
          // },
          // {
          //   label: "紧急联系人",
          //   prop: "sosName",
          // },
          // {
          //   label: "紧急联系人手机号",
          //   prop: "sosPhone",
          // },
          // {
          //   label: "是否参加业绩排行",
          //   prop: "isJoin",
          //   type: "select",
          //   dicData: [
          //     {
          //       label: "是",
          //       value: "1",
          //     },
          //     {
          //       label: "否",
          //       value: "2",
          //     },
          //   ],
          // },
          // {
          //   label: "人天",
          //   prop: "val",
          // },
          // {
          //   label: "工龄",
          //   prop: "workDays",
          //   formatter: (row, column, cellValue) => {
          //     return `${cellValue}天`;
          //   },
          // },
          // {
          //   label: "年龄",
          //   prop: "age",
          // },
          {
            label: '积分',
            prop: 'integral',
          },
          {
            label: "入职时间",
            prop: "createTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
          },
          {
            label: "简历",
            prop: "resume",
          },
        ],
      },
      ids: [], //选择数据

      dialogVisible_role: false, //用户角色管理
      dialogVisible_edit: false, //用户编辑
      dialogVisible_add: false, //用户添加
      dialogVisible_view: false, //用户查看
      dialogVisible_viewResume: false,
      choiceId: "",
      page: 1,
      pageSize: 10,
      fullName: "",
      phone: "",
      username: "",

      pageSync: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: false,
        pageSize: 10,
      },

      fileList: [],
      headers: {
        Authorization: this.$store.state.user.Token,
      },
      action: "/api/web/system/withdraw/payment/import",
    };
  },
  mounted() {
    let that = this;
    that.Buttons = that.$store.state.index.menuSubButtons;
    // console.log(this.Buttons)
    this.userDeptChooseList();
    this.userChooseList();

    that.getRole(); //用户管理-角色列表

    that.$root.eventHub.$on("changeRole", (n) => {
      that.dialogVisible_role = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeEdit", (n) => {
      that.dialogVisible_edit = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeAdd", (n) => {
      that.dialogVisible_add = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeView", (n) => {
      that.dialogVisible_view = false;
    });
    that.$root.eventHub.$on("changeViewResume", (n) => {
      that.dialogVisible_viewResume = false;
      if (n) {
        that.user_list();
      }
    });
  },
  methods: {
    onDownloadClick() {
      window.open("http://47.103.193.100:8888/down/zej6awjiCdLQ", "_self");
    },
    aaa() {
      window.open("/api/web/system/withdraw/payment/export", "_self");
    },
    // 文件校验
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 2;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过2M！");
      }
      return fileType && fileLimit;
    },
    // 导入成功
    handleAvatarSuccess(res, file) {
      console.log('res',res.data);
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.list = res.data;
        this.user_list();
      } else {
        this.$message.error(res.msg);
      }
    },
    async workListView(id) {
      this.$router.push({
        name: "systemUserWorkView",
        params: { id },
      });
    },
    // 部门列表
    async userDeptChooseList() {
      try {
        const { data } = await userDeptChooseList();
        this.deptChooseList = data;
      } catch (error) {
        console.error(error);
      }
    },
    // 职级列表
    async userChooseList() {
      try {
        const { data } = await userChooseList();
        this.chooseList = data;
      } catch (error) {
        console.error(error);
      }
    },

    ...mapActions(["getRole"]),
    //用户管理-列表
    user_list() {
      userlist({
        page: this.pageSync.currentPage,
        size: this.pageSync.pageSize,
        // name: this.search.name,
        username: this.search.fullName,
        phone: this.search.phone,
        isWork: this.search.isWork,
        // phone: this.phone,
        // username: this.username,
      })
        .then((res) => {
          this.data = res.data.records;
          this.pageSync.total = res.data.total;
          this.pageSync.currentPage = res.data.current;
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    onLoad() {
      this.user_list();
    },
    handleGrant(form) {
      this.dialogVisible_role = true;
      this.choiceId = form.id;
    },
    clickedit(form) {
      this.choiceId = form.id;
      this.dialogVisible_edit = true;
    },
    clickVewResume(form) {
      this.choiceId = form.id;
      this.dialogVisible_viewResume = true;
    },
    clickMenu(name, form) {
      console.log(name, 1111, form);
      if (name == "add") return (this.dialogVisible_add = true);
      if (name == "cel") return this.UserDelete(form.id);
      if (name == "auth") return this.handleGrant(form);
      if (name == "edit") return this.clickedit(form);
      if (name == "viewResume") return this.clickVewResume(form.id);
      // if(name == '查看') return this.clickView(form);
      if (name == "reset") {
        // console.log(form)
        this.PasswordReset(form);
      }
    },
    PasswordReset(form) {
      this.$confirm(`密码重置`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            passwordReset(form.id)
              .then((res) => {
                if (res.code === 200) {
                  this.$notify({
                    title: "重置密码为123456",
                    duration: 5000,
                    type: "success",
                  });
                  this.$message.success(res.msg);
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    clickView(form) {
      this.choiceId = form.id;
      this.dialogVisible_view = true;
    },
    UserDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            userDelete(id == undefined ? this.ids : id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.user_list();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    searchQuery() {
      // console.log(params)
      // this.fullName = params.fullName == undefined ? "" : params.fullName;
      // this.phone = params.phone == undefined ? "" : params.phone;
      // this.username = params.username == undefined ? "" : params.username;
      this.user_list();
      // done();
    },
    // searchReset(params) {
    //   this.fullName = params.fullName;
    //   this.phone = params.phone;
    //   this.username = params.username;
    //   this.user_list();
    // },
    searchReset() {
      // console.log(form)
      this.pageSync.currentPage = 1;

      this.search = {
        fullName: "",
        phone: "",
        isWork: "",
      };

      this.user_list();
    },
  },
};
</script>

<style scoped>
::v-deep .avue-crud__left {
  display: flex !important;
  /* flex-wrap: wrap;
  margin-bottom: 20px; */
}
</style>
