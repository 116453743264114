import { Delete, get, post, put } from '../axios'

// 荣誉管理-列表
export const manageList = params => get(`/web/log/list`,params);

// // 文章分类-新增
// export const manageAdd = params => post(`/web/project/classify/add`,params);

// // 文章分类-查看
// export const manageView = params => get(`/web/honor/label/view/${params}`);

// // 文章分类-修改
// export const manageUpdate = params => put(`/web/honor/label/update`,params);

// // 文章分类-删除
// export const manageDelete = params => Delete(`/web/project/classify/delete/${params}`);
