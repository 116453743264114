<template>
  <div class="search">
    <el-button
      type="primary"
      class="margin"
      @click="increase"
    >新增</el-button>
    <!-- <el-input
      v-model="form.name"
      placeholder="请输入项目类型"
      class="margin"
    ></el-input>
    <el-button
      type="primary"
      @click="searchQuery"
      style="margin-bottom: 10px"
    >查询</el-button>
    <el-button
      type="info"
      @click="searchReset"
      style="margin-bottom: 10px"
    >重置</el-button> -->

  </div>
</template>

<script>
export default {
  name: "search",
  props: {},
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    increase() {
      this.$emit("clickIncrease");
    },
    searchQuery() {
      this.$emit("changeSearch", this.form);
    },
    searchReset() {
      this.form = {
        name: "",
      };
      this.$emit("changeSearch", this.form);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.search > .el-input {
  width: 300px;
}
.margin {
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
