<template>
  <div class="main-content">
    <!-- @update="update" -->
    <pagesHeader title="部门管理" />
    <avue-tree
      :loading="loading"
      :option="option"
      :data="data"
      @save="save"
      @del="del"
      v-model="form"
    >
      <span
        class="el-tree-node__label"
        slot-scope="{ node, data }"
      >
        <span>
          <i class="el-icon-user-solid"></i>
          {{ (node || {}).label }}
        </span>
      </span>

    </avue-tree>
  </div>
</template>

<script>
import { deptMenuTree, deptAdd, deptDelete } from "@/api/system/dept";

export default {
  data() {
    return {
      form: {},
      loading: true,
      data: [],
      option: {
        title: "",
        filterText: "搜索关键字自定义",
        defaultExpandAll: true,
        // addBtnText: "新增自定义文案",
        // editBtnText: "修改自定义文案",
        // delBtnText: "删除自定义文案",
        menu: false,
        // addBtn: false,
        // delBtn: false,
        // editBtn: false,
        defaultExpandedKeys: [1],
        formOption: {
          labelWidth: 120,
          column: [
            {
              label: "部门名称",
              prop: "label",
              span: 20,
              rules: [
                {
                  required: true,
                  message: "输入部门名称",
                  trigger: "blur",
                },
              ],
              maxlength: 30,
            },
            {
              label: "排序值",
              prop: "sort",
              span: 20,
              rules: [
                {
                  required: true,
                  message: "输入排序值",
                  trigger: "blur",
                },
                { pattern: /^[0-9]*$/, message: "请输入数字" },
              ],
              maxlength: 8,
            },
          ],
        },
        props: {
          labelText: "标题",
          label: "name",
          value: "id",
          level: "level",
          parent: 0,
          sort: 0,
          children: "children",
        },
      },
    };
  },
  mounted() {
    this.deptMenuTree();
    this.loading = false;
  },
  methods: {
    deptMenuTree() {
      deptMenuTree()
        .then((res) => {
          if (res.code === 200) {
            const { data } = res;
            this.data = data;
            this.dialogVisiblePower = true;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },

    async deptDelete(id, done) {
      try {
        await deptDelete('b1c979724bfefcf67d0617b3c781cf17');
        this.$message.success("删除成功");
        this.deptMenuTree();
      } catch (error) {
        console.error(error);
      } finally {
        done();
      }
    },
    del(data, done) {
      this.$confirm(`此操作将永久删除${data.name}部门, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deptDelete(data.id, done);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // async depUpdate(newData, done) {
    //   console.log(newData);
    //   try {
    //     await deptAdd(newData);
    //     this.$message.success("添加成功");
    //     this.deptMenuTree();
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     done();
    //   }
    // },
    // update(node, data, done, loading) {
    //   console.log(node, data);
    //   this.$message.success("更新回调");
    //   done();
    // },
    async deptAdd(newData, done) {
      console.log(newData);
      try {
        await deptAdd(newData);
        this.$message.success("添加成功");
        this.deptMenuTree();
      } catch (error) {
        console.log(error);
      } finally {
        done();
      }
    },
    save(node, data, done, loading) {
      const newData = {
        deptName: data.label,
        level: node.level ? node.level : 1,
        parent: node.id ? node.id : 0,
        sort: data.sort,
      };
      this.deptAdd(newData, done);
    },
  },
};
</script>