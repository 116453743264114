<template>
  <el-dialog
    :title="increaseType.id?'编辑':'新增'"
    width="800px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >
    </avue-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { actionUrl } from "@/config/public";
import column from "./column";
import { ruleAdd, ruleView, ruleUpdate } from "@/api/topic/classify";
export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      form: {
        ruleImg: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.columnIncrease,
      },
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        customConfig: {},
        propsHttp: {
          url: "path",
          res: "data",
        },
      },
    };
  },
  mounted() {
    const ruleImg = this.findObject(this.option.column, "ruleImg");
    ruleImg.headers.Authorization = this.$store.state.user.Token;
    if (this.increaseType.id) {
      this.GET_infoView();
    }
  },
  methods: {
    handleSubmit(form) {
      // console.log(form)
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          if (this.increaseType.id == "") {
            this.GET_infoAdd(form, done);
          } else {
            this.GET_infoUpdate(form, done);
          }
        }
      });
    },
    GET_infoAdd(form, done) {
      ruleAdd(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      ruleUpdate(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    GET_infoView() {
      ruleView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        this.form.ruleImg = res.path;
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
</style>
