<template>
  <div class="main-content">
    <pagesHeader title="查看详情" />

    <div>
      <div>
        用户名称：{{dataInfo.fullName}}
      </div>
      <div>
        问卷名称：{{dataInfo.questionName}}
      </div>
      <div>
        问卷起止时间：{{dataInfo.timeStart}} ~ {{dataInfo.timeEnd}}
      </div>
      <div>
        问卷状态：{{dataInfo.state}}
      </div>

      <div
        v-for="(item,index) in dataInfo.questionAnswers"
        :key="index"
      >
        <h3>
          问卷回答:
        </h3>
        <div>
          问题名称：{{item.question}}
        </div>
        <div class="question-list">
          <div class="question-list-info">
            自评分数：{{item.num}}分
          </div>
          <div class="question-list-info">
            部门负责人：{{item.leaderCheck}}分
          </div>
          <div class="question-list-info">
            运营总监：{{item.bossCheck}}分
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { answerView } from "@/api/question/answer";
import { formatterTime, isStateHandle } from "@/utils/tools";

export default {
  name: "classify",
  data() {
    return {
      dataInfo: {
        fullName: "",
        questionName: "",
        timeStart: "",
        timeEnd: "",
        state: "",
        questionAnswers: [],
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if (this.id) {
      this.answerView();
    }
  },
  methods: {
    async answerView() {
      try {
        const { data } = await answerView(this.id);
        this.dataInfo = {
          ...data,
          timeStart: data.timeStart,
          timeEnd: data.timeEnd,
          state: isStateHandle(data.state),
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 30px 0 20px 0;
}
div {
  margin-bottom: 20px;
}
.question-list {
  display: flex;
}
.question-list-info {
  margin-right: 20px;
}
</style>
