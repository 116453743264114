import { actionUrl } from '@/config/public';
const manage = {
    column: [
        {
            label: '评测名称',
            prop: 'name',
            // width: 80
        },
        // {
        //     label: '总监',
        //     prop: 'reCheckUserIds',
        // },
        // {
        //     label: '组长',
        //     prop: 'checkUserIds',
        // },
        // {
        //     label: '员工',
        //     prop: 'userIds',
        // },
        // {
        //     label: '联系人',
        //     prop: 'contact',
        // },
        // {
        //     label: '联系人电话',
        //     prop: 'contactPhone',
        // },
        // {
        //     label: '项目金额',
        //     prop: 'projectMoney',
        //     // width: 80
        // },
        // {
        //     label: '交付时间',
        //     prop: 'endTime',
        //     // width: 100
        // },
        // {
        //     label: '创建时间',
        //     prop: 'createTime',
        //     // width: 100
        // },
        // {
        //     label: '倒计时',
        //     prop: 'education',
        //     // width: 100
        // },
        // {
        //     label: '组员',
        //     prop: 'school',
        //     // width: 150
        // },
        // {
        //     label: '星座',
        //     prop: 'constellation',
        //     width: 100
        // },
        // {
        //     label: '身高/cm',
        //     prop: 'height',
        //     width: 100
        // },
        // {
        //     label: '体重/kg',
        //     prop: 'weight',
        //     width: 100
        // },
        // {
        //     label: '现居地址',
        //     prop: 'currentProvince',
        //     width: 100
        // },
        // {
        //     label: '注册时间',
        //     prop: 'createTime',
        //     width: 150
        // },
        // {
        //     label: '是否启用',
        //     prop: 'isBan',
        //     type: 'switch',
        //     dicData: [
        //         {
        //             label: '是',
        //             value: true
        //         },
        //         {
        //             label: '否',
        //             value: false
        //         },

        //     ],
        // },

    ],
    detailColumn: [
        {
            label: '用户头像',
            prop: 'avatarUrl',
            type: 'upload',
            accept: "image/*",
            listType: 'picture-img',
            span: 18,
            width: 140,
            row: true,
            propsHttp: {
                res: 'data',
                url: 'avatarUrl',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            rules: [{
                required: true,
                message: "请上传用户头像",
                trigger: "blur"
            }],
        },
        {
            label: "日期",
            prop: "birthday",
            type: "date",
            span: 18,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
                type: 'datetime',
                format: 'yyyy-MM-dd'
            },
            rules: [{
                required: true,
                message: "请输入日期",
                trigger: "blur"
            }],
        },
        {
            label: '用户真实姓名',
            prop: 'nickname',
            width: 140,
            span: 18,
            rules: [{
                required: true,
                message: "请输入用户真实姓名",
                trigger: "blur"
            }],
        },
        {
            label: '手机号',
            prop: 'phone',
            span: 18,
            width: 140,
            rules: [{
                required: true,
                message: "请输入手机号",
                trigger: "blur"
            }],
        },
        {
            label: '性别',
            prop: 'sex',
            type: "select",
            dicData: [
                {
                    label: '未知',
                    value: 0
                },
                {
                    label: '男',
                    value: 1
                },
                {
                    label: '女',
                    value: 2
                }
            ],
            width: 140,
            span: 18,
            rules: [{
                required: true,
                message: "请选择用户性别",
                trigger: "blur"
            }],
        },
        {
            label: '学校',
            prop: 'school',
            type: 'select',
            span: 18,
            width: 140,
            rules: [{
                required: true,
                message: "请输入学校",
                trigger: "blur"
            }],
        },
        {
            label: '身高',
            prop: 'height',
            span: 18,
            width: 140,
            rules: [{
                required: true,
                message: "请输入身高",
                trigger: "blur"
            }],
        },
        {
            label: '体重',
            prop: 'weight',
            span: 18,
            width: 140,
            rules: [{
                required: true,
                message: "请输入体重",
                trigger: "blur"
            }],
        },
        {
            label: '现住省',
            prop: 'currentProvince',
            type: 'select',
            width: 140,
            span: 8,
            rules: [{
                required: true,
                message: "请选择省份",
                trigger: "blur"
            }],
        },
        {
            label: '现住市',
            prop: 'currentCity',
            type: 'select',
            width: 100,
            span: 8,
            rules: [{
                required: true,
                message: "请选择市",
                trigger: "blur"
            }],
        },
        {
            label: '现住区',
            prop: 'currentArea',
            type: 'select',
            width: 100,
            span: 8,
            rules: [{
                required: true,
                message: "请选择区",
                trigger: "blur"
            }],
        },
        {
            label: '家乡省',
            prop: 'hometownProvince',
            type: 'select',
            width: 140,
            span: 8,
            rules: [{
                required: true,
                message: "请选择省份",
                trigger: "blur"
            }],
        },
        {
            label: '家乡市',
            prop: 'hometownCity',
            type: 'select',
            width: 100,
            span: 8,
            rules: [{
                required: true,
                message: "请选择市",
                trigger: "blur"
            }],
        },
        {
            label: '家乡区',
            prop: 'hometownArea',
            type: 'select',
            width: 100,
            span: 8,
            rules: [{
                required: true,
                message: "请选择区",
                trigger: "blur"
            }],
        },
        {
            label: '位置是否隐藏',
            prop: 'isPositionHide',
            type: "select",
            dicData: [
                {
                    label: '隐藏',
                    value: 1
                },
                {
                    label: '不隐藏',
                    value: 2
                },
            ],
            width: 300,
            span: 18,
            rules: [{
                required: true,
                message: "请选择用户性别",
                trigger: "blur"
            }],
        },
    ],
}
export default manage
