import { Delete, get, post, put } from '../axios'

// 用户列表-列表
export const userManageList = params => get(`/web/user/manage/list`,params);

// 用户列表-新增
export const userManageAdd = params => post(`/web/user/manage/user/add`,params);

// 用户列表-查看
export const userManageView = params => get(`/web/user/manage/aboutMeView/${params}`);

// 用户列表-修改
export const userManageUpdate = params => put(`/web/user/manage/user/update`,params);

// 用户列表-删除
export const userManageDelete = params => Delete(`/web/user/user/delete/${params}`);

// 用户列表-状态修改
export const userManageStateUpdate = params => put(`/web/user/manage/update`,params);

// // 用户列表-配置积分
// export const userManagePointsUpdate = params => put(`/web/user/manage/points/update`,params);

// 用户列表-导出
// /web/user/manage/export

// 用户列表-下载导入模板
export const userManageWhiteExport = params => get(`/web/user/manage/white/export`,params);

// 用户列表-白名单导入
// web/user/manage/white/import

// 区域巴选择（首级传0）
export const userRegionBar = params => get(`/web/user/region/bar/${params}`);

// 用户列表-白名单导入
// /web/user/manage/white/import

// // 区域巴-修改
// export const regionUpdate = params => put(`/web/user/manage/region/bar/update`,params);

// 用户列表-等级级别-列表
export const userLevelList = params => get(`/web/user/level/list`,params);

// 用户列表-用户身份-列表
export const userRoleTtpeList = params => get(`/web/user/roleTtpe/list`,params);
