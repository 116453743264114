import { Delete, get, post, put } from '../axios'

// 简历分类-列表
export const manageList = params => get(`/web/recruit/type/list`,params);

// 简历分类-新增
export const manageAdd = params => post(`/web/recruit/type/add`,params);

// 简历分类-查看
export const manageView = params => get(`/web/recruit/type/view/${params}`);

// 简历分类-修改
export const manageUpdate = params => put(`/web/recruit/type/update`,params);

// 简历分类-删除
export const manageDelete = params => Delete(`/web/recruit/type/delete/${params}`);

