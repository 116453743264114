import { get, post, put, Delete } from '../axios'

// 用户管理-列表
export const userManageList = params => get(`/web/system/rank/list`,params);

// 用户管理-添加
export const userManageAdd = params => post(`/web/system/rank/add`,params);

// 用户管理-编辑
export const userManageStateUpdate = params => put(`/web/user/manage/state/update`,params);

// 用户管理-查看
export const userView = params => get(`/web/system/rank/view/${params}`);

// 用户管理-修改
export const userUpdate = params => put(`/web/system/rank/update`,params);

// 用户管理-删除
export const userDelete = params => Delete(`/web/system/rank/delete/${params}`);
