import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '用户名',
            prop: 'username'
        },
        {
            label: '操作名称',
            prop: 'operation',
        },
        {
            label: '操作时长',
            prop: 'time',
        },
        {
            label: '方法',
            prop: 'method',
        },
        {
            label: '参数',
            prop: 'params',
        },
        {
            label: 'ip地址',
            prop: 'ip',
        },
        {
            label: '创建时间',
            prop: 'createTime',
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
        }
    ],
    columnIncrease: [
        {
            label: '荣誉名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入荣誉名称',
            rules: [{
                required: true,
                message: "请输入荣誉名称",
                trigger: "blur"
            }],

        },
        {
            label: '图标',
            prop: 'img',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传图标",
                trigger: "blur"
            }],
        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            maxlength: 8,
            row: true,
            rules: [{
                required: true,
                message: "输入排序值",
                trigger: "blur"
            }],
        }
    ]
}
export default column
