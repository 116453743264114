import { Delete, get, post, put } from '../axios'

// 用户列表-列表
export const userManageList = params => get(`/web/project/prepare/list`,params);

// 用户列表-新增
export const userManageAdd = params => post(`/web/project/manage/add`,params);

// 用户列表-查看
export const userManageView = params => get(`/web/project/prepare/workTeam/${params}`);

// 用户列表-修改
export const userManageUpdateDelete = params => put(`/web/project/prepare/choose`,params);

// 用户列表-删除
export const userManageDelete = params => Delete(`/web/user/user/delete/${params}`);

// 用户列表-状态修改
export const userManageStateUpdate = params => put(`/web/user/manage/update`,params);

// 用户列表-查看
export const singView = params => get(`/web/project/prepare/workTeam/${params}`);