<template>
  <div class="main-content">
    <pagesHeader title="报名记录" />

    <div style="marginTop: 50px">
      <!-- <div class="flex">
        <div class="flex-list">
          <div class="flex-left">
            <h3>报名记录:</h3>
          </div>
        </div>
      </div> -->
      <div>

        <div v-if="registrationRecordList.length>0">
          <div
            v-for="(item,index) in registrationRecordList"
            :key="index"
            class="staff-box"
          >

            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">项目名称:</div>
                <div class="flex-right">{{item.projectName}}</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">项目类型:</div>
                <div class="flex-right">{{item.classify}}</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">发起人名称:</div>
                <div class="flex-right">{{item.teamLeader}}</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">项目团队:</div>
                <div class="flex-right">
                  {{item.workTeams.toString(',')}}
                </div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">总计人日:</div>
                <div class="flex-right">{{item.workDays}}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex"
          v-else
        >
          <div class="flex-list">
            <div class="flex-left">
            </div>
            <div class="flex-right">
              暂无报名记录
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getRegistrationRecordList } from "@/api/itemEntry/manage";
import { stateHandle } from "@/utils/tools";
import dayjs from "dayjs";

export default {
  name: "edit",
  data() {
    return {
      registrationRecordList: [],
    };
  },
  mounted() {
    this.getRegistrationRecordList();
  },
  methods: {
    async getRegistrationRecordList() {
      try {
        const { data } = await getRegistrationRecordList(this.$route.params.id);
        this.registrationRecordList = data;
        console.log("data111", data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.staff-box {
  margin-bottom: 20px;
}
.staff-list {
  margin: -10px 0;
}
.flexs {
  display: grid;
  grid-template-columns: 33% 33% 33% !important;
}
.flex {
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 48% 48% !important;
}

.flex .flex-list {
  margin: 10px 0;
  display: flex;
  /* width: 50%; */
}
.flex .flex-left {
  text-align: right;
  width: 140px;
  margin-right: 10px;
}

.flex .flex-right {
  width: 400px;
}

.margin-bottom-20 {
  margin: 40px 0;
}
</style>
