import { Delete, get, post, put } from '../axios'

// 用户列表-列表
export const userManageList = params => get(`/web/project/manage/list`,params);

// 项目录入
export const userManageAdd = params => post(`/web/project/manage/add`,params);

// 用户列表-查看
export const userManageView = params => get(`/web/project/manage/view/${params}`);

// 用户列表-修改
export const userManageUpdate = params => put(`/web/project/manage/update`,params);

// 用户列表-删除
export const userManageDelete = params => Delete(`/web/user/user/delete/${params}`);

// 用户列表-状态修改
export const userManageStateUpdate = params => put(`/web/user/manage/update`,params);

// // 项目立项-新增
// export const sureAdd = params => post(`/web/project/manage/sure`,params);

