import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '员工姓名',
            prop: 'name'
        },
        {
            label: '员工职级',
            prop: 'levelName'
        },
        {
            label: '年度业绩完成率',
            prop: 'achievementNow',
            formatter: (row, column, cellValue) => {
                return `${cellValue}%`;
            }
        },
    ],
    columnIncrease: [
        {
            label: '名称',
            prop: 'title',
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "输入话题名称，最多15个字符",
                trigger: "blur"
            }],

        },
        {
            label: '背景图',
            prop: 'backgroundImage',
            type: 'upload',
            accept: "image/jpeg,image/png",
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path'
            },
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传背景图",
                trigger: "blur"
            }],
        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "输入排序值",
                trigger: "blur"
            }],
        },
        {
            label: '内容',
            prop: 'content',
            span: 24,
            row: true,
            maxlength: 100,
            rules: [{
                required: true,
                message: "输入话题内容",
                trigger: "blur"
            }],
        },
        {
            label: '推荐',
            prop: 'isRecommend',
            span: 24,
            row: true,
            type: 'radio',
            dicData: [
                {
                    label: '推荐',
                    value: 1
                },
                {
                    label: '不推荐',
                    value: 2
                }
            ],
            rules: [{
                required: true,
                message: "是否推荐",
                trigger: "blur"
            }],
        },
        {
            label: '话题分类',
            prop: 'topicClassifyId',
            span: 24,
            row: true,
            type: 'select',
            dicData: [],
            props: {
                label: 'name',
                value: 'id'
            },
            rules: [{
                required: true,
                message: "选择话题分类",
                trigger: "blur"
            }],
        }
    ]
}
export default column
