<template>
  <div class="main-content">
    <pagesHeader title="消息发布" />
    <avue-form
      ref="form"
      v-model="form"
      :option="option"
    >

      <!-- <template slot="honorId">
        <el-select
          v-model="form.honorId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in selectsendList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </template> -->

      {{mulSelecteds}}
      {{selectOptions}}

      <template slot="userIds">
        <MultipleSelect
          :selectOptions="selectOptions"
          :mulSelectLoading="mulSelectLoading"
          :mulSelecteds="form.userIds"
          @update:updateMultipleSelect="val => form.userIds = val"
        >
        </MultipleSelect>
      </template>

      <template slot="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标题"
        ></el-input>
      </template>

      <template slot="message">
        <avue-ueditor
          v-model="form.message"
          v-bind="uOptions"
        ></avue-ueditor>
        </template>

    </avue-form>

    <div class="submit-button">
      <!-- <el-button
        size="medium"
        @click="$router.go(-1)"
      >取消</el-button> -->
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { pushAdd, pushView, pushUpdate } from "@/api/topic/push";
import { projectListHandle } from "@/api/selectApi/selectApi";
import column from "./column";
import { actionUrl } from "@/config/public";
import MultipleSelect from "./multipleSelect.vue";

export default {
  name: "Add",
  components: {
    MultipleSelect,
  },
  data() {
    return {
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        customConfig: {},
        propsHttp: {
          url: "path",
          res: "data",
        },
      },
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.increaseColumn,
      },
      mulSelectLoading: false,
      mulSelecteds: [],
      selectOptions: [],
    };
  },
  mounted() {
    this.projectListHandle();
    if (this.id !== "0") {
      this.pushView(this.id);
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async pushView(id) {
      try {
        const { data } = await pushView(id);
        this.form = {
          ...data,
          userIds: data.userIds.split(','),
        };
      } catch (error) {
        console.log(error);
      }
    },
    async projectListHandle() {
      this.mulSelectLoading = true;
      try {
        const { data } = await projectListHandle();

        this.selectOptions = data.map((item) => {
          return {
            label: item.fullName,
            value: item.id,
          };
        });
        this.mulSelectLoading = false;
        this.mulSelecteds = [];
      } catch (error) {
        console.log(error);
      }
    },

    handleSubmit() {
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          const newData = {
            ...this.form,
            userIds: this.form.userIds.toString(),
          };
          if (this.id === '0') {
            pushAdd(newData).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$router.push({
                  name: "newsMessagePushList",
                });
              }

              done();
            });
          } else {
             pushUpdate(newData).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$router.push({
                  name: "newsMessagePushList",
                });
              }

              done();
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 50px 0;
}

.submit-button .el-button {
  padding: 10px 50px;
}

.avue-form >>> .el-input__suffix {
  display: none;
}

.el-icon-circle-plus-outline {
  position: relative;
  top: 60px;
  left: 50%;
  font-size: 40px;
}
.el-icon-remove-outline {
  position: relative;
  top: 60px;
  left: 51%;
  font-size: 40px;
}

.el-input__inner,
.el-input__inner {
  width: 100% !important;
}
</style>
