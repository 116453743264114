import { Delete, get, post, put } from '../axios'

// 文章分类-列表
export const classifyList = params => get(`/web/top/achievement/list`,params);

// 文章分类-选择器列表
export const classifySelectList = () => get(`/web/news/manage/add/classifyList`);

// 文章分类-新增
export const classifyAdd = params => post(`/web/news/manage/add`,params);

// 文章分类-查看
export const classifyView = params => get(`/web/news/manage/view/${params}`);

// 文章分类-修改
export const classifyUpdate = params => put(`/web/news/manage/update`,params);

// 文章分类-删除
export const classifyDelete = params => Delete(`/web/news/classify/delete/${params}`);


