<template>
  <el-dialog
    :title="`${increaseType.id? '编辑':'新增'}话题`"
    width="600px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >
      <template slot="title">

        <avue-input
          v-model="form.title"
          placeholder="请输入话题名称，最多15个字符"
        ></avue-input>
      </template>
    </avue-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import column from "./column";
import {
  topicAdd,
  topicView,
  topicUpdate,
  topicSelectData,
} from "@/api/topic/manage";
export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      form: {
        icon: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.columnIncrease,
      },
    };
  },
  mounted() {
    const backgroundImage = this.findObject(
      this.option.column,
      "backgroundImage"
    );
    backgroundImage.headers.Authorization = this.$store.state.user.Token;
    if (this.increaseType.id != "") {
      this.GET_infoView();
    }
    this.topicSelectData();
  },
  methods: {
    async topicSelectData() {
      try {
        const { data } = await topicSelectData();
        const topicClassifyId = this.findObject(
          this.option.column,
          "topicClassifyId"
        );
        topicClassifyId.dicData = data;
      } catch (error) {
        console.error(error);
      }
    },
    handleSubmit(form) {
      // console.log(form)
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          if (form.title.length > 15) {
            this.$message.error("名称长度最多15个字符");
            done();
            return;
          }
          if (this.increaseType.id == "") {
            this.GET_infoAdd(form, done);
          } else {
            this.GET_infoUpdate(form, done);
          }
        }
      });
    },
    GET_infoAdd(form, done) {
      topicAdd({
        title: form.title,
        backgroundImage: form.backgroundImage,
        sort: form.sort,
        content: form.content,
        isRecommend: form.isRecommend,
        topicClassifyId: form.topicClassifyId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      topicUpdate({
        id: form.id,
        title: form.title,
        backgroundImage: form.backgroundImage,
        sort: form.sort,
        content: form.content,
        isRecommend: form.isRecommend,
        topicClassifyId: form.topicClassifyId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    GET_infoView() {
      topicView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        this.form.backgroundImage = res.path;
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
</style>
