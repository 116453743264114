<template>
  <div class="search">
    <el-button
      type="primary"
      class="margin"
      @click="increase"
    >新增</el-button>
    <div class="flex align-items margin">
      <div class="width300">
        <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="~"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <el-input
      v-model="form.name"
      placeholder="请输入荣誉名称"
      class="margin"
    ></el-input>
    <el-button
      type="primary"
      @click="searchQuery"
      style="margin-bottom: 10px"
    >查询</el-button>
    <el-button
      type="info"
      @click="searchReset"
      style="margin-bottom: 10px"
    >重置</el-button>

  </div>
</template>

<script>
export default {
  name: "search",
  props: {},
  data() {
    return {
      form: {
        date: [],
        name: "",
      },
    };
  },
  methods: {
    increase() {
      this.$emit("clickIncrease");
    },
    searchQuery() {
      this.$emit("changeSearch", this.form);
    },
    searchReset() {
      this.form = {
        date: [],
        name: "",
      };
      this.$emit("changeSearch", this.form);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.search > .el-input {
  width: 300px;
}
.margin {
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
