<template>
  <div class="main-content">
    <pagesHeader title="项目报名" />
    <!-- <Search
      @changeSearch="changeSearch"
      @changeIncrease="changeIncrease"
    /> -->
    <avue-crud
      ref="crud"
      :data="data"
      :option="option"
      :page.sync="pageSync"
      @on-load="onLoad"
    >

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <!-- <el-button
          size="small"
          type="text"
          @click="userDetail(row.id)"
        >详情</el-button> -->
        <el-button
          size="small"
          type="text"
          @click="changeEdit(row.id)"
        >编辑</el-button>

        <!-- <el-button
          size="small"
          type="text"
          @click="userDelete(row.id)"
        >删除</el-button> -->
      </template>
      <template
        slot="isBan"
        slot-scope="{ row }"
      >
        <el-switch
          v-model="row.isBan"
          @change="accountStatus(row.id,row.isBan)"
        ></el-switch>
      </template>
    </avue-crud>
    <Information
      v-if="information.type"
      :information="information"
    />
    <Integral
      v-if="integral.type"
      :integral="integral"
    />
    <Region
      v-if="region.type"
      :region="region"
    />
    <Increase
      v-if="increaseType.type"
      :increaseType="increaseType"
    />
  </div>

</template>

<script>
// import Search from "./serach.vue";
import Information from "./information";
import Integral from "./integral";
import Region from "./region";
import Increase from "./increase";
import manage from "./column.js";
import {
  userManageList,
  // userManageStateUpdate,
  // userManageDelete,
  // userManageUpdate,
} from "@/api/itemEntry/sign";
export default {
  name: "manage",
  components: {
    // Search,
    Information,
    Integral,
    Region,
    Increase,
  },
  data() {
    return {
      data: [],
      option: {
        align: "center",
        menuAlign: "center",
        addBtn: false,
        delBtn: false,
        editBtn: false,
        viewBtn: false,
        header: false,
        border: true,
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: manage.column,
      },
      pageSync: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: false,
        pageSize: 10,
      },
      search: {},
      information: {
        type: false,
        province: "",
        city: "",
        county: "",
        detail: "",
        name: "",
      },
      integral: {
        type: false,
        id: "",
      },
      region: {
        type: false,
        id: "",
      },
      increaseType: {
        type: false,
        id: "",
      },
    };
  },
  mounted() {
    this.$root.eventHub.$on("changeUserManage", (n) => {
      this.increaseType = {
        type: false,
        id: "",
      };
      this.information = {
        type: false,
        province: "",
        city: "",
        county: "",
        detail: "",
        name: "",
      };
      this.integral = {
        type: false,
        id: "",
      };
      this.region = {
        type: false,
        id: "",
      };
      if (n) {
        this.GET_userManageList();
      }
    });
  },
  methods: {
    onLoad() {
      this.GET_userManageList();
    },
    GET_userManageList() {
      userManageList({
        page: this.pageSync.currentPage,
        size: this.pageSync.pageSize,
        //搜索请求参数
        createTimeStart: this.search.createTimeStart, //注册时间开始
        createTimeEnd: this.search.createTimeEnd, //注册时间结束
        phone: this.search.phone, //手机号
        name: this.search.name, //用户昵称
        school: this.search.school, //用户昵称
      })
        .then((res) => {
          if (res.code === 200) {
            this.data = res.data.records.map((item) => {
              if (item.currentCity) {
                return {
                  ...item,
                  currentProvince: `${item.currentProvince}/${item.currentCity}`,
                  isBan: item.isBan === 1 ? true : false,
                };
              }
              return {
                ...item,
                isBan: item.isBan === 1 ? true : false,
              };
            });
            this.pageSync.total = res.data.total;
            this.pageSync.currentPage = res.data.current;
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 搜索/重置
    changeSearch(e) {
      // console.log(e)
      this.search = e;
      this.pageSync.currentPage = 1;
      this.GET_userManageList();
    },
    // 新增
    changeIncrease() {
      this.increaseType.type = true;
    },
    // 编辑
    changeEdit(id) {
      // this.increaseType = row;
      // this.increaseType.type = true;
      this.$router.push({
        name:'manageSignEdit',
        params: {id}
      })
    },
    // 禁用/启用
    // accountStatus(id, i) {
    //   userManageStateUpdate({
    //     id: id,
    //     isBan: i ? 1 : 2,
    //   })
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.$message.success("修改成功");
    //         this.GET_userManageList();
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message.error(err.msg);
    //     });
    // },
    userDetail(id) {
      console.log(id);
      this.increaseType = {
        type: true,
        id: id,
      };
    },
    // 删除
    userDelete(id) {
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          userManageDelete(id)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功!");
                this.GET_userManageList();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        })
        .catch(() => {
          this.$message.info("已取消删除!");
        });
    },
    //门店员工认证信息w
    viewInfo(row) {
      this.information = {
        type: true,
        province: row.storeProvince,
        city: row.storeCity,
        county: row.storeCounty,
        detail: row.storeDetail,
        name: row.storeName,
      };
    },
    //配置积分
    allocationIntegral(id) {
      this.integral = {
        type: true,
        id: id,
      };
    },
    userSet(id) {
      this.region = {
        type: true,
        id: id,
      };
    },
  },
};
</script>

<style scoped>
</style>
