<template>
  <el-dialog
    :title="this.increaseType.id?'编辑黑榜':'新增黑榜'"
    width="800px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >

      <template slot="projectId">
        <el-select
          v-model="form.projectId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in selectProjectNameList"
            :key="item.id"
            :label="item.projectName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="punish">
        <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6}"
            placeholder="请输入内容"
            v-model="form.punish"
        >
        </el-input>
      </template>

      <template slot="causeBy">
        <avue-ueditor
          v-model="form.causeBy"
          v-bind="uOptions"
        ></avue-ueditor>
      </template>

      <template slot="away">
        <avue-ueditor
          v-model="form.away"
          v-bind="uOptions"
        ></avue-ueditor>
      </template>

      <template slot="end">
        <el-select
          v-model="form.end"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in endList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

    </avue-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import column from "./column";
import { manageAdd, manageView, manageUpdate } from "@/api/banner/black";
import { projectNameHandle } from "@/api/selectApi/selectApi";
import { actionUrl } from "@/config/public";

export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      uOptions: {
        action: actionUrl,
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        customConfig: {},
        propsHttp: {
          url: "path",
          res: "data",
        },
      },
      customConfig: {},
      props: {
        url: "path",
        res: "data",
        name: "path",
      },
      endList: [
        {
          id: "卓越",
          name: "卓越",
        },
        {
          id: "优秀",
          name: "优秀",
        },
        {
          id: "合格",
          name: "合格",
        },
        {
          id: "事故",
          name: "事故",
        },
        {
          id: "重大事故",
          name: "重大事故",
        },
        {
          id: "烂尾",
          name: "烂尾",
        },
      ],
      selectProjectNameList: [],
      form: {
        // img: "",
        punish:"",
        causeBy: "",
        away: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.columnIncrease,
      },
    };
  },
  mounted() {
    // const img = this.findObject(this.option.column, "img");
    // img.headers.Authorization = this.$store.state.user.Token;
    if (this.increaseType.id) {
      this.manageView();
    }
    this.projectNameHandle();
  },
  methods: {
    async projectNameHandle() {
      try {
        const { data } = await projectNameHandle();
        this.selectProjectNameList = data;
      } catch (error) {
        console.log(error);
      }
    },
    handleSubmit(form) {
      console.log(form)
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          if (this.increaseType.id === "") {
            this.GET_infoAdd(form, done);
          } else {
            this.GET_infoUpdate(form, done);
          }
        }
      });
    },
    GET_infoAdd(form, done) {
      const { showTime, ...rest } = form;
      manageAdd({
        ...rest,
        startTime: showTime[0].slice(0, 10) + " 00:00:00",
        endTime: showTime[1].slice(0, 10) + " 23:59:59",
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      const { showTime, ...rest } = form;
      manageUpdate({
        ...rest,
        startTime: showTime[0].slice(0, 10) + " 00:00:00",
        endTime: showTime[1].slice(0, 10) + " 23:59:59",
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    manageView() {
      manageView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            const { startTime, endTime, punish, ...rest } = res.data;
            this.form = {
             ...rest,
              punish: punish[0],
              showTime: [startTime, endTime],
            }

          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        // this.form.img = res.path;
        // this.form.causeBy = res.path;
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
</style>
