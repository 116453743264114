<template>
  <el-dialog
    :title="increaseType.id?'编辑':'新增'"
    width="800px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
    >
    </avue-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import column from "./column";
import { userManageAdd, userView, userUpdate } from "@/api/user/list";
export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 160,
        column: column.columnIncrease,
      },
    };
  },
  mounted() {
    if (this.increaseType.id) {
      this.GET_infoView();
    }
  },
  methods: {
    handleSubmit(form) {
      // console.log(form)
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          if (this.increaseType.id) {
            this.GET_infoUpdate(form, done);
          } else {
            this.GET_infoAdd(form, done);
          }
        }
      });
    },
    GET_infoAdd(form, done) {
      userManageAdd(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      form.id = this.increaseType.id
      userUpdate(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    GET_infoView() {
      userView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
</style>
