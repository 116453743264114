import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '制度名称',
            prop: 'ruleName'
        },
        {
            label: '制度规则',
            prop: 'ruleImg',
            type: 'upload',
            listType: 'picture-img',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true
        },
        // {
        //     label: '排序值',
        //     prop: 'sort'
        // },
    ],
    columnIncrease: [
        {
            label: '制度名称',
            prop: 'ruleName',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入制度名称',
            rules: [{
                required: true,
                message: "请输入制度名称",
                trigger: "blur"
            }],
        },
        {
            label: '封面图',
            prop: 'ruleImg',
            type: 'upload',
            listType: 'picture-img',
            propsHttp: {
                res: 'data',
                url: 'path',
                name: ''
            },
            tip: '(最多上传1张)',
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "上传封面图",
                trigger: "blur"
            }],
        },
        {
            label: '制度规则',
            prop: 'content',
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "请填写制度规则",
                trigger: "blur"
            }]
        },
        {
            label: '排序值',
            prop: 'sort',
            type: 'number',
            minRows: 1,
            maxRows: 999999,
            span: 24,
            row: true,
            placeholder: '请输入排序值',
            rules: [{
                required: true,
                message: "请输入排序值",
                trigger: "blur"
            }],
        }

    ]
}
export default column
