import {get, post, put,Delete} from '../axios'


// 角色管理-菜单树形结构
export const deptMenuTree = params => get('/web/system/dept/list',params);

// 角色管理-新增
export const deptAdd = params => post('/web/system/dept/add',params);

// 角色管理-查看
export const deptView = params => get(`/web/system/role/view/${params}`);

// 角色管理-修改
export const deptUpdate = params => put('/web/system/role/update',params);

// 角色管理-删除
export const deptDelete = params => Delete(`/web/system/dept/delete/${params}`);

