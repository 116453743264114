import { get, post } from '../axios'

// 项目分类-下拉框
export const classificationListHandle = () => get(`/web/project/classify/list`);

// 员工列表-下拉框
export const projectListHandle = () => get(`/web/project/user/list`);

// 商务列表-下拉框
export const contactBusinessListHandle = () => get(`/web/project/user/listB`);

// 项目列表-下拉框
export const projectNameHandle = () => get(`/web/banner/project/listB`);

// 问卷列表-下拉框
export const questionHandle = () => get(`/web/evaluate/question/listB`)

// 颁发荣誉-下拉框
export const sendHandle = () => get(`/web/honor/label/listB`)

// 颁发荣誉-员工列表传type 1，2，3
export const staffViewHandle = params => get(`/web/system/user/works/${params}`);

// 颁发荣誉-下拉框
export const questionTypeHandle = () => get(`/web/evaluate/question/typeList`)

// 招聘分类（招聘员工岗位）- 下拉框
export const recruitTypeList2 = () => get(`/web/recruit/type/list2`)

// 颁发荣誉-员工列表传type 1，2，3
export const getContactBusinessPhone = params => get(`/web/system/user/phone/${params}`);