<template>
  <div class="main-content">
    <pagesHeader title="项目录入" />
    <avue-form
      ref="form"
      v-model="form"
      :option="option"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >

      <template slot="contactBusiness">
        <el-select
          v-model="form.contactBusiness"
          placeholder="请选择"
          filterable
          @change="getContactBusinessHandle"
        >
          <el-option
            v-for="item in contactBusinessList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          ></el-option>
        </el-select>
      </template>

      <template slot="state">
        <el-select
          v-model="form.state"
          placeholder="请选择"
          @change="onStateHandle"
        >
          <el-option
            v-for="item in stateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </template>

      <template slot="classify">
        <el-select
          v-model="form.classify"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </template>

      <template slot="overTime">
        <el-date-picker
          v-model="form.overTime"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </template>

      <template slot="des">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="form.des"
        >
        </el-input>
      </template>

      <template slot="function">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="form.function"
        >
        </el-input>
      </template>

      <template slot="projectMoney">
        <el-input
          oninput="value=value.replace(/[^\d]/g,'');"
          placeholder="请输入内容"
          v-model="form.projectMoney"
        >
        </el-input>
      </template>

    </avue-form>

    <div v-if="isState>2 &&  this.isState !== 7">
      <el-form
        label-width="80px"
        v-for="(item,index) in projectWorkActualList"
        :key="index"
      >
        <el-form-item>
          <i
            class="el-icon-circle-plus-outline"
            @click="addProjectWorkActualList"
          ></i>
          <i
            v-if="index>0"
            class="el-icon-remove-outline"
            @click="delProjectWorkActualList(index)"
          ></i>
        </el-form-item>

        <div style="width: 50%">
          <el-form-item :label="index === 0?'项目经理':'开发组员'">
            <el-select
              v-model="projectWorkActualList[index].userId"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in selctProjectWorkActualList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              >
              </el-option>
            </el-select>

          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="开发时间">
            <el-date-picker
              v-model="projectWorkActualList[index].time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开工时间"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="项目业绩">
            <el-input
              oninput="value=value.replace(/[^\d]/g,'');"
              v-model="projectWorkActualList[index].achievement"
            ></el-input>
          </el-form-item>
        </div>

        <div style="width: 50%">
          <el-form-item label="开发人日">
            <el-input
              oninput="value=value.replace(/[^\d]/g,'');"
              v-model="projectWorkActualList[index].timeSpend"
            ></el-input>
          </el-form-item>
        </div>
        <div style="width: 50%">
          <el-form-item label="项目奖金">
            <el-input
              oninput="value=value.replace(/[^\d]/g,'');"
              v-model="projectWorkActualList[index].money"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="submit-button">
      <el-button
        size="medium"
        @click="$router.go(-1)"
      >取消
      </el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit"
      >确定
      </el-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { pickBy } from "lodash";
import { userManageAdd } from "@/api/itemEntry/add";
import {
  classificationListHandle,
  contactBusinessListHandle,
  staffViewHandle,
  getContactBusinessPhone,
} from "@/api/selectApi/selectApi";
import column from "./column";

export default {
  name: "Add",
  data() {
    return {
      isState: 0,
      stateList: [
        {
          label: "未成交",
          value: 7,
        },
        {
          label: "待成交",
          value: 1,
        },
        {
          label: "待立项",
          value: 2,
        },
        {
          label: "开发中",
          value: 3,
        },
        {
          label: "待测试",
          value: 4,
        },
        {
          label: "待质检",
          value: 5,
        },
        {
          label: "完工",
          value: 6,
        },
      ],
      classifyList: [],
      contactBusinessList: [],
      selctProjectWorkActualList: [],
      projectWorkActualList: [
        {
          userId: "",
          achievement: "",
          time: [],
          timeSpend: "",
          money: "",
        },
      ],
      form: {
        overImg: "",
        startImg: "",
        testImg: "",
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 160,
        column: column.increaseColumn2,
      },
    };
  },
  watch: {
    // isState(value) {
    //   if (value > 2) {
    //     const startImg = this.findObject(this.option.column, "startImg");
    //     startImg.headers.Authorization = this.$store.state.user.Token;
    //   }
    //   if (value > 5) {
    //     const testImg = this.findObject(this.option.column, "testImg");
    //     testImg.headers.Authorization = this.$store.state.user.Token;
    //   }
    //   if (value === 6) {
    //     const overImg = this.findObject(this.option.column, "overImg");
    //     overImg.headers.Authorization = this.$store.state.user.Token;
    //   }
    // },
  },
  mounted() {
    // const testImg = this.findObject(this.option.column, "testImg");
    // testImg.headers.Authorization = this.$store.state.user.Token;
    // const overImg = this.findObject(this.option.column, "overImg");
    // overImg.headers.Authorization = this.$store.state.user.Token;
    // const startImg = this.findObject(this.option.column, "startImg");
    // startImg.headers.Authorization = this.$store.state.user.Token;

    this.contactBusinessListHandle();
    console.log(123123)
    this.classificationListHandle();
    this.projectListHandle();
  },
  methods: {
    async getContactBusinessHandle(e) {
      const dataInfo = this.contactBusinessList.find((item) => item.id === e);
      this.form.contactPhone = dataInfo.phone;
      // try {
      //   const { data } = await getContactBusinessPhone(e);
      //   this.form.contactPhone = data;
      // } catch (error) {
      //   console.error(error);
      // }
    },
    onStateHandle(e) {
      // console.log("e", e);
      this.isState = e;
      // if (e === 3 || e === 4) {
      //   this.option.column = column.increaseColumn1;
      // } else if (e === 6 || e === 5) {
      //   this.option.column = column.increaseColumn2;
      // } else {
      //   this.option.column = column.increaseColumn;
      // }
    },
    async contactBusinessListHandle() {
      try {
        const { data } = await contactBusinessListHandle();
        this.contactBusinessList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async classificationListHandle() {
      try {
        const { data } = await classificationListHandle();
        this.classifyList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async projectListHandle() {
      try {
        const { data } = await staffViewHandle(3);
        this.selctProjectWorkActualList = data;
      } catch (error) {
        console.log(error);
      }
    },
    delProjectWorkActualList(index) {
      this.projectWorkActualList.splice(index, 1);
    },
    addProjectWorkActualList() {
      const isStata = this.projectWorkActualList.find(
        (item) =>
          !item.userId ||
          !item.achievement ||
          item.time.length < 1 ||
          !item.timeSpend
      );
      if (isStata && this.isState > 2 && this.isState !== 7) {
        this.$message({
          message: "请先完善信息在添加输入框！",
          type: "warning",
        });

        return;
      }
      this.projectWorkActualList.push({});
    },
    handleSubmit() {
      const isStata = this.projectWorkActualList.find(
        (item) =>
          !item.userId ||
          !item.achievement ||
          item.time.length < 1 ||
          !item.timeSpend
      );
      if (isStata && this.isState > 2 && this.isState !== 7) {
        console.log(isStata);
        this.$message({
          message: "请先完善信息在保存！",
          type: "warning",
        });

        return;
      }
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          const newProjectWorkActualList = this.projectWorkActualList.map(
            (item, index) => {
              if (index === 0) {
                return {
                  userId: item.userId,
                  achievement: item.achievement,
                  timeStart: dayjs(item.time[0]).format("YYYY-MM-DD HH:mm:ss"),
                  timeEnd: dayjs(item.time[1]).format("YYYY-MM-DD HH:mm:ss"),
                  timeSpend: item.timeSpend,
                  money: item.money,
                  type: 1,
                };
              }
              return {
                userId: item.userId,
                achievement: item.achievement,
                timeStart: dayjs(item.time[0]).format("YYYY-MM-DD HH:mm:ss"),
                timeEnd: dayjs(item.time[1]).format("YYYY-MM-DD HH:mm:ss"),
                timeSpend: item.timeSpend,
                money: item.money,
                type: 2,
              };
            }
          );

          if (this.form?.receivingTime) {
            this.form.prepareStart = this.form.receivingTime[0]
              ? dayjs(this.form.receivingTime[0]).format("YYYY-MM-DD HH:mm:ss")
              : "";
            this.form.prepareEnd = this.form.receivingTime[1]
              ? dayjs(this.form.receivingTime[1]).format("YYYY-MM-DD HH:mm:ss")
              : "";
          }

          if (this.form?.workTime) {
            this.form.workStart = this.form.workTime[0]
              ? dayjs(this.form.workTime[0]).format("YYYY-MM-DD HH:mm:ss")
              : "";
            this.form.workEnd = this.form.workTime[1]
              ? dayjs(this.form.workTime[1]).format("YYYY-MM-DD HH:mm:ss")
              : "";
          }

          if (this.form?.testTime) {
            this.form.testStart = this.form.testTime[0]
              ? dayjs(this.form.testTime[0]).format("YYYY-MM-DD HH:mm:ss")
              : "";
            this.form.testEnd = this.form.testTime[1]
              ? dayjs(this.form.testTime[1]).format("YYYY-MM-DD HH:mm:ss")
              : "";
          }
          if (this.form?.qualityInspectionTime) {
            this.form.qualityTestStart = this.form.qualityInspectionTime[0]
              ? dayjs(this.form.qualityInspectionTime[0]).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
            this.form.qualityTestEnd = this.form.qualityInspectionTime[1]
              ? dayjs(this.form.qualityInspectionTime[1]).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
          }

          // if (this.isState > 2) {
          if (this.form?.establishmentTime) {
            this.form.establishmentStart = this.form.establishmentTime[0]
              ? dayjs(this.form.establishmentTime[0]).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
            this.form.establishmentEnd = this.form.establishmentTime[1]
              ? dayjs(this.form.establishmentTime[1]).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
          }
          // }

          // if (this.isState === 6 || this.isState === 5) {
          if (this.form?.overTime) {
            this.form.overTime = dayjs(this.form.overTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          // }

          const from = pickBy(this.form, (value) => value !== "");

          const newData = from;
          // if (this.isState > 2 && this.isState !== 7) {
          newData.projectWorkActualListList = newProjectWorkActualList;
          // }

          userManageAdd(newData).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "录入成功",
                type: "success",
              });
              this.$router.push({
                name: "manageList",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    GetRotationBannerAdd(done) {},
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        if (column.prop === "startImg") {
          this.form.startImg = res.path;
        }
        if (column.prop === "overImg") {
          this.form.overImg = res.path;
        }
        if (column.prop === "testImg") {
          this.form.testImg = res.path;
        }
      });
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 50px 0;
}

.submit-button .el-button {
  padding: 10px 50px;
}

.avue-form >>> .el-input__suffix {
  display: none;
}

.el-icon-circle-plus-outline {
  position: relative;
  top: 60px;
  left: 50%;
  font-size: 40px;
}

.el-icon-remove-outline {
  position: relative;
  top: 60px;
  left: 51%;
  font-size: 40px;
}

.el-input__inner,
.el-input__inner {
  width: 100% !important;
}
</style>
