export const indexOption = {
    card: false,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: true, // 显示索引
    indexLabel: '序号',
    indexWidth: 150,
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align:'center',
    menuAlign:'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '真实姓名',
            prop: 'fullName'
        },
        {
            label: '头像',
            prop: 'headPortrait',
            type: 'img',
        },
        {
            label: '创建时间',
            prop: 'createTime'
        },
        {
            label: '状态',
            prop: 'state',
            formatter: (row) => {
                // 状态：1-待审核；2-已审核；3-审核驳回
                switch (row.state) {
                    case 1:
                        return '待审核'
                    case 2:
                        return '已审核'
                    case 3:
                        return '审核驳回'
                    default:
                        return '/'
                }
            }
        },
        {
            label: '审核时间',
            prop: 'examineTime',
            formatter: (row) => {
                return row.examineTime ? row.examineTime : '/'
            }
        }
    ]
}