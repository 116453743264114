import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '用户名称',
            prop: 'userName'
        },
        {
            label: '问卷所属时间起',
            prop: 'timeStart'
        },
        {
            label: '问卷所属时间止',
            prop: 'timeEnd'
        },
        {
            label: '问卷名称',
            prop: 'questionName',
        },
        {
            label: '状态',
            prop: 'state',
        },
        {
            label: '自评总分',
            prop: 'totalNum1',
        },
        {
            label: '组长总分',
            prop: 'totalNum2',
        },
        {
            label: '总监总分',
            prop: 'totalNum3',
        },
        {
            label: '评测时间',
            prop: 'createTime',
        }
    ],
    columnIncrease: [
        {
            label: "总监",
            prop: "reCheckUserId",
            span: 24,
            showWordLimit: true,
            rules: [
                {
                    required: true,
                    message: "请输入总监",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "组长",
            prop: "checkUserId",
            span: 24,
            type: 'select',
            showWordLimit: true,
            dicData: [],
            rules: [
                {
                    required: true,
                    message: "请输入组长",
                    trigger: "blur",
                },
            ],
        },
        {
            label: "员工",
            prop: "userIds",
            span: 24,
            showWordLimit: true,
            // rules: [
            //     {
            //         required: true,
            //         message: "请输入员工",
            //         trigger: "blur",
            //     },
            // ],

        },
        {
            label: '问卷',
            prop: 'questionId',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请选择问卷',
            rules: [{
                required: true,
                message: "请选择问卷",
                trigger: "blur"
            }],
        },
        {
            label: "展示时间",
            prop: "time",
            type: "date",
            span: 24,
            placeholder: '请选择问卷所属时间',
            rules: [{
                required: true,
                message: "请选择问卷所属时间",
                trigger: "blur"
            }],
        },
    ]
}
export default column
