<template>
  <div class="main-content">
<h3>查看详情</h3>
    <div style="margin-top: 40px">

      <div class="flex">
        <div class="left">黑榜名称：</div>
        <div class="right">{{form.name}}</div>
      </div>

      <div class="flex">
      <div class="left">项目名称：</div>
      <div class="right">{{form.projectName}}</div>
      </div>

      <div class="flex">
        <div class="left">项目处罚：</div>
        <div class="right"> {{form.punish.join(',')}}</div>
      </div>


      <div class="flex">
        <div class="left">项目结果：</div>
        <div class="right">
          <p v-html="form.away"></p>
<!--          {{form.away}}-->
        </div>
      </div>


      <div class="flex">
        <div class="left">避坑指南：</div>
        <div class="right">
          {{form.end}}
        </div>
      </div>




    </div>
  </div>
</template>

<script>
import {manageView} from "@/api/banner/black";

export default {
  data() {
    return {
      form:{}
    }
  },
  created() {
    this.manageView();
  },
  methods: {
    manageView() {
      manageView(this.$route.params.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style>
.flex{
  display: flex;
  margin-bottom: 20px;
}
.left{
  width:140px;
}
.right {
  width: 500px !important;
}


</style>