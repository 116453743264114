import { Delete, get, post, put } from '../axios'

// 绩效考评-列表
export const typeList = params => get(`/web/evaluate/question/typeList`,params);

// 文章分类-新增
export const typeAdd = params => post(`/web/evaluate/question/typeAdd`,params);

// 文章分类-查看
export const typeView = params => get(`/web/evaluate/questionType/view/${params}`);

// 文章分类-修改
export const typeUpdate = params => put(`/web/evaluate/question/typeUpdate`,params);

// 文章分类-删除
export const typeDelete = params => Delete(`/web/evaluate/question/typeDelete/${params}`);