<template>
  <div>
    <div class="main-box">
      <div class="hello">
        <div
          class="icons left-icon"
          @click="clickPrevious"
        >
          <img
            src="@/style/image/left-icon.png"
            alt=""
          >
        </div>

        <div
          class="icons right-icon"
          @click="clickNext"
        >
          <img
            src="@/style/image/right-icon.png"
            alt=""
          >
        </div>
        <Search
          @changeSearch="changeSearch"
          @clickIncrease="clickIncrease"
          class="search-box"
        />

        <div class="threetype">
          <span
            class='typeflag flag1'
            @click="getStateList(3)"
          >开发中</span>&nbsp;&nbsp;
          <span
            class='typeflag flag2'
            @click="getStateList(4)"
          >提测中</span>&nbsp;&nbsp;
          <span
            class='typeflag flag3'
            @click="getStateList(8)"
          >报警中</span>&nbsp;&nbsp;
          <span
            class='typeflag flag4'
            @click="getStateList(2)"
          >立项中</span>&nbsp;&nbsp;
          <span
            class='typeflag flag5'
            @click="getStateList(5)"
          >质检中</span>&nbsp;&nbsp;
          <span
            class='typeflag flag6'
            @click="getStateList(9)"
          >将结算</span>&nbsp;&nbsp;
        </div>

        <el-calendar
          ref="uploadImgBtn"
          :first-day-of-week="1"
          class='main'
          v-model="calendarInfo"
        >

          <template
            slot="dateCell"
            slot-scope="{date, data,}"
          >
            <div
              class="item"
              :data-date="date"
              @click="getDayList(date)"
            >
              <div class="top">
                <div class="twodate">{{solarDate2lunar(data.day,'two')}}</div>
                <div class="onedate">{{solarDate2lunar(data.day,'one')}}</div>
                <div class="flag-box">
                  <span
                    class="flag1"
                    v-if="my_state2(data.day)"
                  ></span>
                  <span
                    class="flag2"
                    v-if="my_state3(data.day)"
                  ></span>
                  <span
                    class="flag3"
                    v-if="my_state4(data.day)"
                  ></span>
                  <span
                    class="flag4"
                    v-if="my_state5(data.day)"
                  ></span>
                  <span
                    class="flag5"
                    v-if="my_state8(data.day)"
                  ></span>
                  <span
                    class="flag6"
                    v-if="my_state9(data.day)"
                  ></span>
                  <!-- <span
                    class="flag7"
                    v-if="my_state9(data.day)"
                  ></span> -->
                </div>
              </div>
              <!-- <div
            class="center"
            :class="getFes(data.day)?'centerone':'centertwo'"
          >
            {{getFes(data.day)}}
          </div> -->
            </div>
          </template>
        </el-calendar>

      </div>
    </div>

    <el-drawer
      :visible.sync="dialogFormVisible"
      :with-header="false"
      :disabled="true"
    >
      <div class="dateBox">
        <div class="dateTitle">
          <h1 class="day">{{dataInfo.day}}</h1>
          <div class="dateInfo">
            <div class="month">
              {{dataInfo.month}}
            </div>
            <div class="year">
              {{dataInfo.year}}
            </div>
          </div>
        </div>

        <div class="dateContent-box">
          <div class="dateContent">
            <div
              class="dateContent-list"
              v-for="(item,index) in dateContentList"
              :key="index"
              :class="`dateContent-list${item.state}`"
            >
              <div
                class="dateContent-list-state dateContent-list-state2"
                v-if="item.state === 2"
              >
                <span>立项中</span>
              </div>
              <div
                class="dateContent-list-state dateContent-list-state3"
                v-if="item.state === 3"
              >
                <span>开发中</span>
              </div>
              <div
                class="dateContent-list-state dateContent-list-state4"
                v-if="item.state === 4"
              >
                <span>提测中</span>
              </div>
              <div
                class="dateContent-list-state dateContent-list-state5"
                v-if="item.state === 5"
              >
                <span>质检中</span>
              </div>
              <div
                class="dateContent-list-state dateContent-list-state8"
                v-if="item.state === 8"
              >
                <span>报警中</span>
              </div>
              <div
                class="dateContent-list-state dateContent-list-state9"
                v-if="item.state === 9"
              >
                <span>将结算</span>
              </div>

              <div class="dateContent-list-right">
                <div class="dateContent-list-head">
                  <div class="dateContent-list-title">
                    {{item.projectName}}
                  </div>

                  <div class="dateContent-list-date">
                    {{item.startDate}} - {{item.endDate}}
                  </div>
                </div>

                <div class="dateContent-list-content">
                  <span style="color: #ABB0C0;">项目参与人员：
                    <span
                      v-for="(items,indexs) in item.personnelList"
                      :key="indexs"
                    >
                      {{items}}
                    </span>
                  </span>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>
 
<script>
import calendar from "./calendar.js";
import dayjs from "dayjs";
import Search from "./search";
import {
  manageList,
  getViewPerformanceHandle,
} from "@/api/top/performanceCalendar";

export default {
  name: "Calendar",
  components: {
    Search,
  },
  data() {
    return {
      dialogFormVisible: false,
      calendarInfo: new Date(),
      // res_date: [
      //   { date: "2022-10-10", content: "查询成绩", info: "fangwei" },
      //   { date: "2022-10-21", content: "报名考试" },
      //   { date: "2022-10-26", content: "生日" },
      // ],
      res_date: [],
      dateInfo: {
        state: "",
        userId: "",
        projectId: "",
        dayNmus: 0,
        day: "",
        month: "",
        year: "",
      },
      dataInfo: {
        day: "",
        month: "",
        year: "",
      },
      dateContentList: [],
    };
  },
  // watch: {
  //   calendarInfo: {
  //     handler(newVal, oldVal) {
  //       console.log("新的值: " + dayjs(newVal).format("YYYY-MM-DD"));
  //       console.log("旧的值: " + dayjs(oldVal).format("YYYY-MM-DD"));
  //     },
  //   },
  // },
  mounted() {
    this.getCalendarData(this.dateInfo);
  },
  methods: {
    async getDayList(date) {
      this.dataInfo = {
        day: this.solarDate2lunar(dayjs(date).format("YYYY-MM-DD"), "two"),
        month: this.solarDate2lunar(dayjs(date).format("YYYY-MM-DD"), "one"),
        year: dayjs(date).format("YYYY年MM月"),
      };
      this.dialogFormVisible = true;
      const times = dayjs(date).format("YYYY-MM-DD");
      try {
        const { data } = await getViewPerformanceHandle({ date: times });
        this.dateContentList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getStateList(state) {
      this.dateInfo.state = state;
      this.getCalendarData(this.dateInfo);
    },
    async changeSearch(e) {
      this.dateInfo.userId = e.userId;
      this.dateInfo.projectId = e.projectId;
      this.getCalendarData(this.dateInfo);
    },
    clickIncrease() {
      this.dateInfo = {
        state: "",
        userId: "",
        projectId: "",
        dayNmus: 0,
        day: "",
        month: "",
        year: "",
      };
      this.getCalendarData(this.dateInfo);
    },
    async getCalendarData(dateInfo) {
      const params = {
        mouth: dateInfo.dayNmus,
        userId: dateInfo.userId,
        projectId: dateInfo.projectId,
        state: dateInfo.state,
      };
      try {
        const { data } = await manageList(params);
        this.res_date = data;
      } catch (error) {
        this.$message.error(error.msg);
      }
    },
    clickPrevious() {
      this.$refs.uploadImgBtn.$children[0].$children[0].$el.click();
      console.log(this.dateInfo);
      this.dateInfo.dayNmus--;
      this.getCalendarData(this.dateInfo);
    },
    clickNext() {
      this.$refs.uploadImgBtn.$children[0].$children[2].$el.click();
      this.dateInfo.dayNmus++;
      this.getCalendarData(this.dateInfo);
    },
    my_state2(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state3;
          break;
        }
      }
      return res;
    },
    my_state3(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state4;
          break;
        }
      }
      return res;
    },
    my_state4(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state8;
          break;
        }
      }
      return res;
    },
    my_state5(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state2;
          break;
        }
      }
      return res;
    },
    my_state8(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state5;
          break;
        }
      }
      return res;
    },
    my_state9(v) {
      let len = this.res_date.length;
      let res = "";
      for (let i = 0; i < len; i++) {
        if (this.res_date[i].date == v) {
          res = this.res_date[i].state9;
          break;
        }
      }
      return res;
    },

    solarDate2lunar(solarDate, type) {
      var solar = solarDate.split("-");
      var lunar = calendar.solar2lunar(solar[0], solar[1], solar[2]);
      if (type === "one") {
        // if (lunar.IDayCn === "初一") {
        //   return lunar.IMonthCn + lunar.IDayCn;
        // }
        return lunar.IMonthCn + lunar.IDayCn;
      }
      // return solar[2] + "日";
      return solar[2];
    },
    // getFes(solarDate) {
    //   var solar = solarDate.split("-");
    //   var lunar = calendar.solar2lunar(solar[0], solar[1], solar[2]);
    //   return lunar.festival ? lunar.festival : lunar.lunarFestival;
    // },
  },
};
</script>

<style scoped>
.main-box {
  padding: 20px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 20px auto;
}
::v-deep .el-calendar-table:not(.is-range) td.next,
::v-deep .el-calendar-table:not(.is-range) td.prev {
  pointer-events: none !important;
}
.main-content {
  padding: 20px 10px;
  width: 100%;
}
::v-deep .el-button-group {
  display: none;
}
::v-deep .el-calendar-table thead th:before {
  content: "周";
}
::v-deep .el-calendar__header {
  margin-top: 20px;
}
::v-deep .el-calendar__title {
  margin-left: 80px;
  transform: translate(-50%);
  font-size: 1.2em;
  font-weight: 500;
  position: relative;
  bottom: 10px;
}
::v-deep .el-calendar__button-group {
  margin-right: 20%;
}

::v-deep .el-calendar__body {
  padding: 0 !important;
}
::v-deep .el-calendar-table__row .is-selected {
  background: none;
}
::v-deep .el-calendar-table__row .is-selected .el-calendar-day {
  border: 2px solid #4f78ff;
}
::v-deep .el-calendar-table td,
::v-deep .el-calendar-table tr,
::v-deep .el-calendar-table th {
  border: none !important;
}
::v-deep .el-calendar-table .el-calendar-day {
  border-radius: 12px;
  height: 100px;
  border: 1px solid #dcdfe6;
  padding: 10px;
  width: 94%;
  margin: 10px auto;
}
::v-deep .el-calendar-table thead th {
  font-weight: 500;
}
::v-deep .main {
  width: 100%;
}
.hello {
  position: relative;
}
.icons {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  background: #4f78ff;
}
.left-icon {
  left: 0;
}
.right-icon {
  left: 170px;
}
.search-box {
  position: absolute;
  top: -6px;
  left: 240px;
}
.threetype {
  position: absolute;
  right: 0%;
  top: 0%;
  font-size: 13px;
  color: #fff;
}
.flag-box {
  position: absolute;
  right: 0;
  top: -10px;
}
.flag-box span {
  display: block;
  width: 20px;
  height: 8px;
  margin-bottom: 4px;
  border-radius: 14px;
  z-index: 0;
}
.flag1 {
  background: #4f78ff;
}
.flag2 {
  background: #9751ff;
}
.flag3 {
  background: #ff5151;
}
.flag4 {
  background: #2cc188;
}
.flag5 {
  background: #ff9e51;
}
.flag6 {
  background: #ababab;
}
.flag7 {
  background: #333333;
}
.typeflag {
  height: 27px;
  width: 64px;
  text-align: center;
  line-height: 27px;
  display: inline-block;
  border-radius: 4px;
}
.typeflag:hover {
  cursor: pointer;
}
.item {
  position: relative;
}
.top {
  position: relative;
  height: 10px;
  font-size: 13px;
}
.center {
  position: absolute;
  width: 112%;
  height: 20px;
  line-height: 20px;
  left: -10px;
  margin-top: 10px;
  margin-left: 0;
  /* border-bottom: 1px dashed #ebe8ec; */
  font-size: 13px;
}
.centerone {
  background: #f0f5f1;
  color: #6cbe77;
}
.centertwo {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.logo {
  width: 0;
  height: 0;
  border: 24px solid;
  border-left: 0;
  transform: rotate(90deg);
  display: inline-block;
  position: absolute;
  left: 3px;
  top: -20px;
}
.onedate {
  margin: 10px 0 0 10px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 549;
  color: #abb0c0;
}
.twodate {
  margin: 10px 0 0 10px;
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 590;
  /* color: #2c3041; */
}
.flagone {
  background: #ccc;
}
.flagtwo {
  background: #28b541;
}
.flagthree {
  background: #f00;
}
.logoimg {
  height: 15px;
  width: 15px;
  position: absolute;
  left: -8px;
  top: -10px;
}
.ulmsg {
  margin-top: 30px;
  height: 50px;
  overflow: hidden;
}
.ulmsg li {
  list-style: none;
  font-size: 13px;
  text-align: left;
  z-index: -1;
}
.addbtn {
  position: absolute;
  right: 10%;
  bottom: 15%;
}
.dateBox {
  padding: 0px 20px 0 20px;
  width: 100%;
}
.dateContent-box {
  margin-top: 100px;
  height: 100%;
}
.dateContent {
  position: absolute;
  width: 100%;
  overscroll-behavior: contain;
  overflow: auto;
  height: calc(100% - 200px);
  margin-right: 20px;
  /* padding-top: 200px; */
  margin-top: 100px;
}
.dateContent .dateContent-list {
  width: calc(100% - 40px);
  word-wrap: break-word;
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  height: 100px;
  overflow: hidden;
}

.dateContent .dateContent-list2 {
  border: 1px solid #2cc188;
}

.dateContent .dateContent-list3 {
  border: 1px solid #4f78ff;
}

.dateContent .dateContent-list4 {
  border: 1px solid #9751ff;
}

.dateContent .dateContent-list5 {
  border: 1px solid #ff9e51;
}

.dateContent .dateContent-list8 {
  border: 1px solid #ff5151;
}

.dateContent .dateContent-list9 {
  border: 1px solid #ababab;
}

.dateContent .dateContent-list-state {
  writing-mode: tb-rl;
  width: 30px;
  text-align: center;
  color: #fff;
  padding-right: 4px;
}

.dateContent .dateContent-list-state2 {
  background: #2cc188;
}
.dateContent .dateContent-list-state3 {
  background: #4f78ff;
}
.dateContent .dateContent-list-state4 {
  background: #9751ff;
}
.dateContent .dateContent-list-state5 {
  background: #ff9e51;
}
.dateContent .dateContent-list-state8 {
  background: #ff5151;
}
.dateContent .dateContent-list-state9 {
  background: #ababab;
}
.dateContent-list-right {
  width: 100%;
  padding: 18px 20px;
}
.dateContent-list-head {
  display: flex;
  justify-content: space-between;
}
.dateContent-list-title {
  font-size: 20px;
}
.dateContent-list-content {
  margin-top: 10px;
}
.dateTitle {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 1;
  padding-top: 70px;
}
.day {
  float: left;
  font-size: 60px;
  /* font-family: PingFangSC-Semibold, PingFang SC; */
  font-weight: 549;
  color: #2c3041;
}
.dateInfo {
  float: left;
  margin-left: 10px;
}
.month {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #abb0c0;
  margin-top: 20px;
}
.year {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2c3041;
}
</style>