<template>
  <el-dialog
    :title="increaseType.id?'查看详情':'新增'"
    width="600px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      v-if="!increaseType.id"
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >
      <template slot="honorId">
        <el-select
          v-model="form.honorId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in selectsendList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </template>

      <template slot="userId">
        <el-select
          v-model="form.userId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in selctProjectWorkActualList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          ></el-option>
        </el-select>
      </template>

      <template slot="reason">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="form.reason"
        >
        </el-input>
      </template>

    </avue-form>

    <div v-if="increaseType.id">
      <div class="list">
        荣誉名称:  {{form.honorName}}
      </div>
      <div class="list">
        用户名称:{{form.fullName}}
      </div>
      <div class="list">
        颁发事由: {{form.reason}}
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import column from "./column";
import { sendAdd, sendView, manageUpdate } from "@/api/honor/manage";
import { sendHandle, staffViewHandle } from "@/api/selectApi/selectApi";

export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      selectsendList: [],
      selctProjectWorkActualList: [],
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.columnIncrease,
      },
    };
  },
  mounted() {
    this.sendHandle();
    this.projectListHandle();
    // const img = this.findObject(this.option.column, "img");
    // img.headers.Authorization = this.$store.state.user.Token;
    console.log(this.$store.state);
    if (this.increaseType.id) {
      this.GET_infoView();
    }
  },
  methods: {
    async projectListHandle() {
      try {
        const { data } = await staffViewHandle(3);
        this.selctProjectWorkActualList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendHandle() {
      try {
        const { data } = await sendHandle();
        this.selectsendList = data;
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    },
    handleSubmit(form) {
      // console.log(form)
      if (this.increaseType.id == "") {
        this.$refs.form.validate((vaild, done) => {
          if (vaild) {
            this.GET_infoAdd(form, done);
          }
        });
      } else {
        // this.GET_infoUpdate(form, done);
        this.handleClose();
      }
    },
    GET_infoAdd(form, done) {
      this.$root.eventHub.$emit("changeInfoClassify", true);
      sendAdd(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            // this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      this.$root.eventHub.$emit("changeInfoClassify", true);
      manageUpdate({
        id: form.id,
        name: form.name,
        img: form.img,
        sort: form.sort,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            // this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    GET_infoView() {
      sendView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        // this.form.img = res.path;
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
.list {
  margin-bottom: 20px;
}
</style>
