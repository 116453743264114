<template>
  <el-dialog
    :title="this.increaseType.id?'编辑':'新增'"
    width="800px"
    :visible.sync="increaseType.type"
    :close-on-click-modal="false"
    :show-close="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
    >
      <template slot="reCheckUserId">
        <el-select
          v-model="form.reCheckUserId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in reCheckUserIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="checkUserId">
        <el-select
          v-model="form.checkUserId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in checkUserIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="userIds">
        <el-select
          v-model="form.userIds"
          multiple
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in userIdsList"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="questionId">
        <el-select
          v-model="form.questionId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in selctQuestionIdList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="time">
        <el-date-picker
          v-model="form.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </template>

    </avue-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit(form)"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import column from "./column";
import { questionHandle } from "@/api/selectApi/selectApi";
import { answerAdd } from "@/api/question/answer";
import dayjs from "dayjs";
import { staffViewHandle } from "@/api/selectApi/selectApi";

export default {
  name: "increase",
  props: {
    increaseType: Object,
  },
  data() {
    return {
      reCheckUserIdsList: [],
      checkUserIdsList: [],
      userIdsList: [],
      selctQuestionIdList: [],
      form: {
        questionId: "",
        time: [],
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.columnIncrease,
      },
    };
  },
  mounted() {
    if (this.increaseType.id) {
      this.manageView();
    }
    this.questionHandle();
    this.reCheckUserIdsListHandle();
    this.checkUserIdsListHandle();
    this.userIdsListHandle();
  },
  methods: {
    async reCheckUserIdsListHandle() {
      try {
        const { data } = await staffViewHandle(1);
        this.reCheckUserIdsList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async checkUserIdsListHandle() {
      try {
        const { data } = await staffViewHandle(2);
        this.checkUserIdsList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async userIdsListHandle() {
      try {
        const { data } = await staffViewHandle(3);
        this.userIdsList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async questionHandle() {
      try {
        const { data } = await questionHandle();
        this.selctQuestionIdList = data;
      } catch (error) {
        console.error(error);
      }
    },
    handleSubmit(form) {
      this.$refs.form.validate((vaild, done) => {
        if (vaild) {
          form.userIds = form.userIds.toString();
          if (this.increaseType.id == "") {
            this.GET_infoAdd(form, done);
          } else {
            this.GET_infoUpdate(form, done);
          }
        }
      });
    },
    GET_infoAdd(form, done) {
      const newData = {
        ...form,
        timeStart: dayjs(form.time[0]).format("YYYY-MM-DD HH:mm:ss"),
        timeEnd: dayjs(form.time[1]).format("YYYY-MM-DD HH:mm:ss"),
      };
      answerAdd(newData)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    GET_infoUpdate(form, done) {
      manageUpdate(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeInfoClassify", true);
          } else {
            this.$message.error(res.msg);
          }
          done();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          done();
        });
    },
    handleClose() {
      this.$root.eventHub.$emit("changeInfoClassify", false);
    },
    manageView() {
      manageView(this.increaseType.id)
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    uploadDelete() {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column)
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      this.$nextTick(() => {
        this.form.img = res.path;
        // this.form.reason = res.path;
      });
    },
  },
};
</script>

<style scoped>
.avue-form >>> .avue-form__menu {
  display: none;
}
</style>
