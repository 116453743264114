<template>
  <div class="main-content">
    <pagesHeader title="详情" />
    <div class="flex">
      <div class="flex-list">
        <div class="flex-left">客户名称:</div>
        <div class="flex-right">{{dataInfo.customerName}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">项目名称:</div>
        <div class="flex-right">{{dataInfo.projectName}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">关联商务:</div>
        <div class="flex-right">{{dataInfo.contactBusinessName}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">联系电话:</div>
        <div class="flex-right">{{dataInfo.contactPhone}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">项目来源:</div>
        <div class="flex-right">{{dataInfo.projectFrom}}</div>
      </div>
    </div>

    <div class="flex">
      <div class="flex-list">
        <div class="flex-left">项目类型:</div>
        <div class="flex-right">{{dataInfo.projectType}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">项目状态:</div>
        <div class="flex-right">{{dataInfo.stateName}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">合同金额:</div>
        <div class="flex-right">{{dataInfo.moneyGet}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">项目业绩:</div>
        <div class="flex-right">{{dataInfo.projectAchievement}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">需求描述:</div>
        <div class="flex-right">{{dataInfo.des}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">文档链接:</div>
        <div class="flex-right">{{dataInfo.function}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">结算时间:</div>
        <div class="flex-right">{{dataInfo.overTime}}</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">开发成本:</div>
        <div class="flex-right">{{dataInfo.moneySpend}}元</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">渠道成本:</div>
        <div class="flex-right">{{dataInfo.moneyTake}}元</div>
      </div>

      <div class="flex-list">
        <div class="flex-left">质检结果:</div>
        <div class="flex-right">{{dataInfo.projectQualityName}}</div>
      </div>

    </div>
    <div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">立项时间:</div>
          <div class="flex-img">{{dataInfo.establishmentStart}} - {{dataInfo.establishmentEnd}}</div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">报名时间:</div>
          <div class="flex-right">{{dataInfo.prepareStart}} - {{dataInfo.prepareEnd}}</div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">开发时间:</div>
          <div class="flex-img">{{dataInfo.workStart}} - {{dataInfo.workEnd}}</div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">测试时间:</div>
          <div class="flex-right">{{dataInfo.testStart}} - {{dataInfo.testEnd}}</div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">质检时间:</div>
          <div class="flex-right">{{dataInfo.qualityTestStart}} - {{dataInfo.qualityTestEnd}}</div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">交付时间:</div>
          <div class="flex-right">{{dataInfo.endTime}}</div>
        </div>
      </div>

      <div class="margin-bottom-20 flexs">

        <!-- v-if="dataInfo.state === 6 || dataInfo.state === 5" -->
        <!-- <div class="margin-bottom-20"> -->

        <div class="flex">
          <div class="flex-list">
            <div class="flex-left">完工单:</div>
            <div class="flex-img">
              <el-image
                v-if="dataInfo.overImg"
                style="width: 100px; height: 100px"
                :src="dataInfo.overImg"
                :preview-src-list="[dataInfo.overImg]"
              >
              </el-image>
            </div>
          </div>
        </div>

        <!-- <div v-if="dataInfo.state === 6 || dataInfo.state === 5"> -->
        <div class="flex">
          <div class="flex-list">
            <div class="flex-left">测试单:</div>
            <div class="flex-img">
              <el-image
                v-if="dataInfo.testImg"
                style="width: 100px; height: 100px"
                :src="dataInfo.testImg"
                :preview-src-list="[dataInfo.testImg]"
              >
              </el-image>

            </div>
          </div>
        </div>
        <!-- </div> -->

        <!-- v-if="dataInfo.state > 2" -->

        <div class="flex">
          <div class="flex-list">
            <div class="flex-left">立项单:</div>
            <div class="flex-img">
              <el-image
                v-if="dataInfo.startImg"
                style="width: 100px; height: 100px"
                :src="dataInfo.startImg"
                :preview-src-list="[dataInfo.startImg]"
              >
              </el-image>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>

    <div style="marginTop: 50px">
      <div class="flex">
        <div class="flex-list">
          <div class="flex-left">
            <h3>员工信息:</h3>
          </div>
        </div>
      </div>
      <div>

        <div v-if="dataInfo.workTeam.length>0">
          <div
            v-for="(item,index) in dataInfo.workTeam"
            :key="index"
            class="staff-box"
          >
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">{{item.type === 1?'项目经理':'开发组员'}}:</div>
                <div class="flex-right">{{item.fullName}}</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">开发时间:</div>
                <div class="flex-right">{{item.startTime}} - {{item.endTime}}</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">项目业绩:</div>
                <div class="flex-right">{{item.achievement}}元</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">开发人日:</div>
                <div class="flex-right">{{item.workDays}}天</div>
              </div>
            </div>
            <div class="flex staff-list">
              <div class="flex-list">
                <div class="flex-left">项目奖金:</div>
                <div class="flex-right">{{item.money}}元</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex"
          v-else
        >
          <div class="flex-list">
            <div class="flex-left">
            </div>
            <div class="flex-right">
              暂无员工信息
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { userManageView } from "@/api/itemEntry/add";
import { stateHandle } from "@/utils/tools";
import column from "./column";
import dayjs from "dayjs";

export default {
  name: "edit",
  data() {
    return {
      dataInfo: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: column.increaseColumn,
      },
    };
  },
  mounted() {
    this.userManageView();
  },
  methods: {
    async userManageView() {
      try {
        const { data } = await userManageView(this.$route.params.id);
        this.oldDataInfo = data;
        this.dataInfo = {
          ...data,
          endTime: data.endTime ? dayjs(data.endTime).format("YYYY-MM-DD") : "",
          establishmentEnd: data.establishmentEnd
            ? dayjs(data.establishmentEnd).format("YYYY-MM-DD HH:mm:ss")
            : "",
          establishmentStart: data.establishmentStart
            ? dayjs(data.establishmentStart).format("YYYY-MM-DD HH:mm:ss")
            : "",
          prepareStart: data.prepareStart
            ? dayjs(data.prepareStart).format("YYYY-MM-DD HH:mm:ss")
            : "",
          prepareEnd: data.prepareEnd
            ? dayjs(data.prepareEnd).format("YYYY-MM-DD HH:mm:ss")
            : "",
          testStart: data.testStart
            ? dayjs(data.testStart).format("YYYY-MM-DD HH:mm:ss")
            : "",
          qualityTestStart: data.qualityTestStart
            ? dayjs(data.qualityTestStart).format("YYYY-MM-DD HH:mm:ss")
            : "",
          qualityTestEnd: data.qualityTestEnd
            ? dayjs(data.qualityTestEnd).format("YYYY-MM-DD HH:mm:ss")
            : "",
          stateName: stateHandle(data.state),
        };
        this.projectWorkActualList = data.workTeam.map((item) => {
          return {
            achievement: item.achievement,
            timeSpend: item.workDays,
            time: [],
            userId: item.userId,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.staff-box {
  margin-bottom: 20px;
}
.staff-list {
  margin: -10px 0;
}
.flexs {
  display: grid;
  grid-template-columns: 33% 33% 33% !important;
}
.flex {
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 48% 48% !important;
}

.flex .flex-list {
  margin: 10px 0;
  display: flex;
  /* width: 50%; */
}
.flex .flex-left {
  text-align: right;
  width: 140px;
  margin-right: 10px;
}

.flex .flex-right {
  width: 400px;
}

.margin-bottom-20 {
  margin: 40px 0;
}
</style>
