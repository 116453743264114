import { actionUrl } from '@/config/public';
const column = {
    column: [
        {
            label: '分类名称',
            prop: 'name'
        },
        // {
        //     label: '图标',
        //     prop: 'img',
        //     type: 'upload',
        //     listType: 'picture-img',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     span: 24,
        //     row: true
        // },
        // {
        //     label: '排序',
        //     prop: 'sort',
        // }
    ],
    columnIncrease: [
        {
            label: '分类名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入分类名称',
            rules: [{
                required: true,
                message: "请输入分类名称",
                trigger: "blur"
            }],

        },
        // {
        //     label: '图标',
        //     prop: 'img',
        //     type: 'upload',
        //     listType: 'picture-img',
        //     propsHttp: {
        //         res: 'data',
        //         url: 'url',
        //         name: 'path'
        //     },
        //     tip: '(最多上传1张)',
        //     action: actionUrl,
        //     headers: {
        //         Authorization: ''
        //     },
        //     span: 24,
        //     row: true,
        //     rules: [{
        //         required: true,
        //         message: "上传图标",
        //         trigger: "blur"
        //     }],
        // },
        // {
        //     label: '排序值',
        //     prop: 'sort',
        //     span: 24,
        //     maxlength: 8,
        //     row: true,
        //     rules: [{
        //         required: true,
        //         message: "输入排序值",
        //         trigger: "blur"
        //     }],
        // }
    ]
}
export default column
