import {get, post, put,Delete} from '../axios'
/* 用户管理-列表 */
export const userlist = params => get('/web/system/user/list',params);

// 用户管理-查看
export const userView = params => get(`/web/system/user/view/${params}`);

// 用户管理-修改
export const userUpdate = params => put('/web/system/user/update',params);

// 用户管理-新增
export const userAdd = params => post('/web/system/user/add',params);

// 用户管理-删除
export const userDelete = params => Delete(`/web/system/user/delete/${params}`);

// 用户管理-角色列表
export const userRole = params => get('/web/system/user/role',params);

// 用户管理-用户角色查看
export const roleView = params => get(`/web/system/user/role/view/${params}`);

// 用户管理-用户角色修改
export const roleUpdate = params => put('/web/system/user/role/update',params);

// 用户管理-密码重置
export const passwordReset = params => get(`/web/system/user/password/reset/${params}`);

// 用户管理-部门列表
export const userDeptChooseList = params => get(`/web/system/user/deptChoose`,params);

// 用户管理-职级列表
export const userChooseList = params => get(`/web/system/user/rankChoose`,params);

// 用户管理-简历查看
export const workListViewList = params => get(`/web/system/work/list/${params}`);

// 用户管理-新增
export const workListViewAdd = params => post('/web/system/work/resume/add',params);

// 用户管理-简历删除
export const workListViewDelete = params => Delete(`/web/system/work/resume/delete/${params}`);