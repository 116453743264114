import { Delete, get, post, put } from '../axios'

// 黑榜-列表
export const manageList = params => get(`/web/banner/black/list`,params);

// 文章分类-新增
export const manageAdd = params => post(`/web/banner/black/add`,params);

// 文章分类-查看
export const manageView = params => get(`/web/banner/black/view/${params}`);

// 文章分类-修改
export const manageUpdate = params => put(`/web/banner/black/update`,params);

// 文章分类-删除
export const manageDelete = params => Delete(`/web/banner/black/delete/${params}`);

