import { Delete, get, post, put } from '../axios'

// 话题-列表
export const topicList = params => get(`/web/news/manage/list`,params);

// 话题-新增
export const topicAdd = params => post(`/web/topic/manage/add`,params);

// 话题-查看
export const topicView = params => get(`/web/topic/manage/view/${params}`);

// 话题-修改
export const topicUpdate = params => put(`/web/topic/manage/update`,params);

// 话题-删除
export const topicDelete = params => Delete(`/web/news/manage/delete/${params}`);

// 话题-主题分类
export const topicSelectData = () => get(`/web/topic/topicClassify/list/`);
