const column = {
    column: [
        {
            label: '黑榜名称',
            prop: 'name'
        },
        {
            label: '项目名称',
            prop: 'projectName'
        },
        {
            label: '项目处罚',
            prop: 'punish'
        },
        // {
        //     label: '项目原因',
        //     prop: 'causeBy'
        // },
        // {
        //     label: '避坑指南',
        //     prop: 'away'
        // },
        {
            label: '项目结果',
            prop: 'end'
        },
        {
            label: '展示时间',
            prop: 'startTime',
            formatter: (row, column, cellValue) => {
                return cellValue + ' ~ ' + row.endTime
            }
        }
    ],
    columnIncrease: [
        {
            label: '黑榜名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入黑榜名称',
            rules: [{
                required: true,
                message: "请输入黑榜名称",
                trigger: "blur"
            }],
        },
        {
            label: '项目名称',
            prop: 'projectId',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入项目名称',
            rules: [{
                required: true,
                message: "请输入项目名称",
                trigger: "blur"
            }],
        },
        {
            label: '项目处罚',
            prop: 'punish',
            span: 24,
            row: true,
            // maxlength: 15,
            placeholder: '请输入项目处罚,如果填写多个请以 , 分开',
            rules: [{
                required: true,
                message: "请输入项目处罚,如果填写多个请以 , 分开",
                trigger: "blur"
            }],
        },
        {
            label: '项目结果',
            prop: 'end',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入项目结果',
            rules: [{
                required: true,
                message: "请输入项目结果",
                trigger: "blur"
            }],
        },
        {
            label: "展示时间",
            prop: "showTime",
            type: "daterange",
            valueFormat: "yyyy-MM-dd",
            span: 24,
            placeholder: '请选择展示时间',
            rules: [{
                required: true,
                message: "请选择展示时间",
                trigger: "blur"
            }],
        },
        {
            label: '项目原因',
            prop: 'causeBy',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入项目原因',
            rules: [{
                required: true,
                message: "请输入项目原因",
                trigger: "blur"
            }],
        },
        {
            label: '避坑指南',
            prop: 'away',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入避坑指南',
            rules: [{
                required: true,
                message: "请输入避坑指南",
                trigger: "blur"
            }],
        },
   
    ]
}
export default column
