const column = {
    column: [
        // {
        //     label: '用户头像',
        //     prop: 'avatarUrl',
        //     slot: true
        // },
        {
            label: '每月业绩/团队业绩',
            prop: 'achievement'
        },
        {
            label: '职级名称	',
            prop: 'levelName'
        },
        {
            label: '岗位薪资	',
            prop: 'salary'
        },
        {
            label: '排序',
            prop: 'sort',
        },
        {
            label: '工作年限/团队人数',
            prop: 'workYears',
        }
    ],
    columnIncrease: [
        {
            label: '每月业绩/团队业绩',
            prop: 'achievement',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入每月业绩/团队业绩',
            rules: [
                {
                    required: true,
                    message: "请输入每月业绩/团队业绩",
                    trigger: "blur"
                },
                { pattern: /^[0-9]*$/, message: "请输入数字" },
            ],

        },
        {
            label: '职级名称',
            prop: 'levelName',
            span: 24,
            row: true,
            maxlength: 8,
            placeholder: '请输入职级名称',
            rules: [{
                required: true,
                message: "请输入职级名称",
                trigger: "blur"
            }],
        },
        {
            label: '岗位薪资',
            prop: 'salary',
            span: 24,
            row: true,
            placeholder: '请输入岗位薪资',
            rules: [
                {
                    required: true,
                    message: "请输入岗位薪资",
                    trigger: "blur",
                },
                { pattern: /^[0-9]*$/, message: "请输入数字" },
            ],
        },
        {
            label: '工作年限/团队人数',
            prop: 'workYears',
            span: 24,
            row: true,
            placeholder: '请输入工作年限/团队人数',
            rules: [
                {
                    required: true,
                    message: "请输入工作年限/团队人数",
                    trigger: "blur",
                },
            ],
            maxlength: 30,

        },
        {
            label: '排序值',
            prop: 'sort',
            span: 24,
            row: true,
            rules: [
                {
                    required: true,
                    message: "输入排序值",
                    trigger: "blur",
                },
                { pattern: /^[0-9]*$/, message: "请输入数字" },
            ],
            maxlength: 8,
        }
    ]
    // state: [
    //     {
    //         label: '正常',
    //         value: 1
    //     },
    //     {
    //         label: '禁用',
    //         value: 2
    //     }
    // ],
}
export default column
