<template>
  <div class="mainPage">
    <div class="login">
      <div class="login-left">

      </div>
      <div class="login-right">
        <div class="login-right-title">盟数</div>
        <div class="form-submit">

          <el-form
            class="login-form"
            status-icon
            :rules="loginRules"
            ref="loginForm"
            :model="loginForm"
            label-width="0"
          >
            <el-form-item prop="username">
              <el-input
                size="small"
                @keyup.enter.native="handleLogin"
                v-model="loginForm.username"
                auto-complete="off"
                placeholder="请输入用户名"
              >
                <i
                  slot="prefix"
                  class="iconfont icon-user1"
                />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                size="small"
                @keyup.enter.native="handleLogin"
                :type="passwordType"
                v-model="loginForm.password"
                auto-complete="off"
                placeholder="请输入密码"
              >
                <i
                  class="el-icon-view el-input__icon"
                  slot="suffix"
                  @click="showPassword"
                />
                <i
                  slot="prefix"
                  class="iconfont icon-password"
                />
              </el-input>
            </el-form-item>
            <div style="display: flex;width: 100%;">

              <el-form-item prop="code">
                <el-input
                  size="small"
                  @keyup.enter.native="handleLogin"
                  v-model="loginForm.code"
                  placeholder="请输入验证码"
                >
                  <i
                  slot="prefix"
                  class="iconfont el-icon-message"
                />
                </el-input>
              </el-form-item>

              <el-form-item prop="codeImg">
                <el-image
                  @click="tabImg"
                  style="width: 150px; height: 40px"
                  :src="loginForm.img"
                ></el-image>
              </el-form-item>

            </div>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                :loading="loading"
                @click.native.prevent="handleLogin"
                class="login-submit"
              >登录
              </el-button>
            </el-form-item>
          </el-form>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { login, imgHandle } from "../../api";
export default {
  name: "login",
  data() {
    return {
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      screenWidth: "",
      divWidth: "",
      loginForm: {
        username: "",
        password: "",
        img: "",
        imgId: "",
        code: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 1, message: "密码长度最少为6位", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { max: 4, message: "验证码长度为4", trigger: "blur" },
        ],
      },
      passwordType: "password",
      loading: false,
    };
  },
  created() {
    this.imgHandle();
    this.screenWidth = document.body.clientWidth;
    if (document.body.clientWidth <= 1000) {
      this.divWidth = document.body.clientWidth * 1 - 40;
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.divWidth = document.body.clientWidth * 1 - 40;
      })();
    };
  },
  methods: {
    tabImg() {
      this.imgHandle();
    },
    async imgHandle() {
      try {
        const { data } = await imgHandle();
        this.loginForm.img = data.img;
        this.loginForm.imgId = data.imgId;
      } catch (error) {
        console.error(error);
      }
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // const loading = this.$loading({
          //     lock: true,
          //     text: '登录中,请稍后。。。',
          //     spinner: "el-icon-loading"
          // });
          login(this.loginForm)
            .then((res) => {
              // 获取数据成功后的其他操作
              if (res.code == 200) {
                this.$router.push("/");
                this.$store.commit("setToken", res.data.token);
                // loading.close();
              } else {
                this.loading = false;
                this.$message.error(res.msg);
                // loading.close();
              }
            })
            .catch((err) => {
              // loading.close();
              this.$message.error(err.msg);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.mainPage {
  width: 100%;
  height: 100vh;
  background: #049ec4;
  background-image: url("../../style/image/loginBg.jpg");
  display: flex;
  align-items: center;
}
.login {
  width: 100%;
  min-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-left {
  width: 500px;
  background-image: url("../../style/image/loginLeft.png");
  height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 15%;
}
.login-right {
  width: 450px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  margin-right: 15%;
  padding: 50px 0;
}
.login-right-title {
  color: #333;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.form-submit {
  width: 325px;
  margin: 0 auto;
}
.form-submit >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.login-submit {
  width: 100%;
  height: 45px;
  border: 1px solid #409eff;
  background: none;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  color: #409eff;
  cursor: pointer;
  margin-top: 30px;
  font-family: "neo";
  transition: 0.25s;
}
</style>
