import { Delete, get, post, put } from '../axios'

// 简历分类-列表
export const manageList = params => get(`/web/recruit/manage/list`,params);

// 简历分类-新增
export const manageAdd = params => post(`/web/recruit/manage/add`,params);

// 简历分类-查看
export const manageView = params => get(`/web/recruit/manage/view/${params}`);

// 简历分类-修改
export const manageUpdate = params => put(`/web/recruit/manage/update`,params);

// 简历分类-删除
export const manageDelete = params => Delete(`/web/recruit/manage/delete/${params}`);

