const column = {
    column: [
        {
            label: '项目名称',
            prop: 'projectName'
        },
        {
            label: '负责内容',
            prop: 'content',
        },
        {
            label: '项目起止时间',
            prop: 'startTime',
        },
        // {
        //     label: '排序值',
        //     prop: 'sort'
        // },
    ],
    columnIncrease: [
        {
            label: '项目名称',
            prop: 'projectName',
            span: 24,
            row: true,
            maxlength: 15,
            placeholder: '请输入项目名称',
            rules: [{
                required: true,
                message: "请输入项目名称",
                trigger: "blur"
            }],
        },
        {
            label: '项目内容',
            prop: 'content',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请输入项目内容',
            rules: [{
                required: true,
                message: "请输入项目内容",
                trigger: "blur"
            }],
        },
        {
            label: '项目起止时间',
            prop: 'time',
            span: 24,
            row: true,
            maxlength: 30,
            placeholder: '请选择项目起止时间',
            rules: [{
                required: true,
                message: "请选择项目起止时间",
                trigger: "blur"
            }],
        },
      
    ]
}
export default column
