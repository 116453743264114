import { Delete, get, post, put } from '../axios'

// 红榜-列表
export const manageList = params => get(`/web/banner/red/list`,params);

// 文章分类-新增
export const manageAdd = params => post(`/web/banner/red/add`,params);

// 文章分类-查看
export const manageView = params => get(`/web/banner/red/view/${params}`);

// 文章分类-修改
export const manageUpdate = params => put(`/web/banner/red/update`,params);

// 文章分类-删除
export const manageDelete = params => Delete(`/web/banner/red/delete/${params}`);


