<template>
  <div class="search">
    <!-- <el-button
      type="primary"
      class="margin"
      @click="increase"
    >新增</el-button> -->
    <el-date-picker
      v-model="form.date"
      type="daterange"
      range-title="注册时间"
      range-separator="~"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      class="date-picker margin"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
    ></el-date-picker>
    <el-input
      v-model="form.name"
      placeholder="请输入名称"
      class="margin"
    ></el-input>
    <el-input
        v-model="form.username"
        placeholder="请输入人员名称"
        class="margin"
    ></el-input>
    <el-select
      v-model="form.state"
      placeholder="请选择"
      class="margin"
    >
      <el-option
        v-for="item in stateList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-button
      type="primary"
      @click="searchQuery"
      style="margin-bottom: 10px"
    >查询</el-button>
    <el-button
      type="info"
      @click="searchReset"
      style="margin-bottom: 10px"
    >重置</el-button>
  </div>
</template>

<script>
import { baseUrl } from "@/config/public.js";
import manage from "./column.js";
import { userRegionBar, userLevelList } from "@/api/user/manage";
export default {
  data() {
    return {
      stateList: [
        {
          label: "未成交",
          value: 7,
        },
        {
          label: "待成交",
          value: 1,
        },
        {
          label: "待立项",
          value: 2,
        },
        {
          label: "开发中",
          value: 3,
        },
        {
          label: "待测试",
          value: 4,
        },
        {
          label: "待质检",
          value: 5,
        },
        {
          label: "完工",
          value: 6,
        },
      ],
      form: {
        date: [],
        createTimeStart: "", //注册时间开始
        createTimeEnd: "", //注册时间结束
        username: "", // 人员名称
        name: "", //用户id/用户昵称/手机号
        state: "", //学校
      },
      state: manage.state,
      name: manage.name,
      options: [],
      action: `${baseUrl}/web/user/manage/list`,
      headers: {
        Authorization: this.$store.state.user.Token,
      },
      fileList: [],
    };
  },
  watch: {
    "form.date"(newValue) {
      if (newValue != null) {
        newValue.forEach((item, index) => {
          if (index === 0) {
            this.form.createTimeStart = item + " 00:00:00";
          } else {
            this.form.createTimeEnd = item + " 23:59:59";
          }
        });
      } else {
        this.form.createTimeStart = "";
        this.form.createTimeEnd = "";
      }
    },
    "form.threeBar"(newValue) {
      // console.log(newValue)
      if (newValue != "") {
        // this.GET_userRegionBar(newValue);
      } else {
        this.form.threeBar = "";
        this.form.threeBarText = "";
      }
      this.twoBar = [];
      this.form.twoBar = "";
      this.form.twoBarText = "";
    },
  },
  mounted() {
    // this.GET_userRegionBar(0);
    // this.GET_userLevelList();
  },
  methods: {
    GET_userLevelList() {
      userLevelList()
        .then((res) => {
          if (res.code === 200) {
            // console.log(res)
            let array = [{ name: "等级级别", level: "" }];
            res.data.forEach((item) => {
              item.name = `等级${item.level}级`;
            });
            this.levelList = array.concat(res.data);
            // console.log(this.levelList)
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    GET_userRoleTtpeList() {},
    increase() {
      this.$emit("changeIncrease");
    },
    searchQuery() {
      this.$emit("changeSearch", this.form);
    },
    searchReset() {
      this.form = {
        date: [],
        state:'',
        name:''
      };
      this.$emit("changeSearch", this.form);
    },
    GET_userRegionBar(e) {
      userRegionBar(e)
        .then((res) => {
          if (res.code === 200) {
            if (e === 0) {
              this.threeBar = res.data;
              this.twoBar = [];
            } else {
              this.twoBar = res.data;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    changeThreeBar(e) {
      // console.log('changeThreeBar',e)
      this.threeBar.forEach((item) => {
        if (item.id === e) {
          this.form.threeBarText = item.name;
        }
      });
    },
    changeTwoBar(e) {
      // console.log('changeTwoBar',e)
      this.twoBar.forEach((item) => {
        if (item.id === e) {
          this.form.twoBarText = item.name;
        }
      });
    },
    GET_userManageWhiteExport() {
      this.$confirm("是否下载导入模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.open(`/api/web/user/manage/white/export`, "_self");
      });
    },
    GET_userManageExport() {
      this.$confirm("是否导出模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // console.log(this.form)
        window.open(
          `/api/web/user/manage/export?activeState=${this.form.activeState}&createTimeStart=${this.form.createTimeStart}&createTimeEnd=${this.form.createTimeEnd}&isIdentity=${this.form.isIdentity}&level=${this.form.level}&name=${this.form.name}&roleType=${this.form.roleType}&state=${this.form.state}`,
          "_self"
        );
      });
    },
    // 文件校验
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过2M！");
      }
      return fileType && fileLimit;
    },
    // 导入成功
    handleAvatarSuccess(res, file) {
      // console.log(res)
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.$root.eventHub.$emit("changeUserManage", true);
        if (res.data.details.length > 0) {
          let text = "";
          res.data.details.forEach((item) => {
            text += item;
          });
          this.$notify({
            title: "提示",
            dangerouslyUseHTMLString: true,
            message: text,
            duration: 0,
          });
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.search {
  /* display: flex;
  flex-wrap: wrap; */
  margin-bottom: 20px;
}
.date-picker >>> .el-range-title {
  width: 20% !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.date-picker >>> .el-range-separator {
  padding: 0;
}
.date-picker >>> .el-range-input {
  width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
  padding: 3px 0px;
}
.search > .el-select {
  width: 200px;
}
.search > .el-input {
  width: 300px;
}
.margin {
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
