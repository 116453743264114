<template>
  <el-dialog
    title="查看简历"
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    11111111111111
  </el-dialog>
</template>

<script>
import { userView, userUpdate } from "@/api/system/index";
export default {
  name: "edit",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    choiceId: String,
  },
  data() {
    return {

    };
  },
  mounted() {
    userView(this.choiceId).then((res) => {
      console.log(this.choiceId);
      this.form = res.data;
      // console.log(res.data)
    });
  },
  methods: {
    handleClose() {
      this.$root.eventHub.$emit("changeViewResume", false);
    },
  },
};
</script>

<style scoped>
</style>
